
.companies {
    margin: 4% 6% 130px 6%;
    

    .companies-grid {
        display: flex;
        justify-items: center;
        flex-wrap: wrap;
        gap: 20px;

        .item {
            display: flex;
            justify-content: center;
            width: calc(25% - 20px);

        }

    }

   
    @media (max-width: 1080px) {
        .companies-grid {
            .item {
                width: calc(33.333% - 20px);

            }
        }

    }

    @media (max-width: 780px) {
        .companies-grid {
            .item {
                width: calc(50% - 20px);
            }
        }

    }

    @media (max-width: 520px) {
        .companies-grid {
            .item {
                width: 100%;
            }
        }
    }
}