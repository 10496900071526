.slider-controls {
    display: flex;
    align-items: center;
    justify-content: center;

    &.mobile-show {
        display: none;
    }

    .prev-btn,
    .next-btn {
        background-color: transparent;
        border: 0;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;

        img {
            width: 40px;
            height: 40px;
        }
    }

    .view-btn {
        color: var(--text-color);
        font-size: var(--font-16);
        font-weight: var(--weight-600);
        line-height: 15px;

        margin: 0 14px;
        background-color: transparent;
        border: none;

        transition: color 0.3s ease;
        white-space: nowrap; 

        &:hover {
            color: var(--primary-color);
        }
    }

    @media (max-width: 580px) {
        &.mobile-show {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.web-show {
            display: none;
        }
    }
}