
.explore {
    margin: 4% 6% 130px 6%;
    margin-top: unset;
    min-height: 300px;

    .title {
        font-weight: var(--weight-400);
        margin-bottom: 40px;

        h1 {
            margin: unset;
            font-size: var(--font-25);
            margin-bottom: 11px;

        }

        .prop-num {
            font-size: var(--font-18);
            color: var(--text-color-light);
        }
    }

        .emptyData {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            .noMatchText{
                max-width: 400px;
                text-align: center;
                position: relative;
                top: 0px;
                right: 0px;
                color: var(--text-color);
                font-size: var(--font-18);
                font-weight: var(--weight-500);
                font-family: var(--primary-font);
            }
           }
   
 
    .props {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 60px;
        gap: 79px;

        .apts {
            width: 100%;
        }

        

    }

    @media (max-width:1023px) {
        .props {
            flex-direction: column;
            gap:20px;
            .apts {
                width: 100%;
            }
            .recomended {
                width: 100%;
            }
        }
    }
}
@media (max-width:575px) {
.explore{
    .emptyData {
      

        .noMatchText{
         max-width: 300px;
         top: -40px;
         right: 0;
        }
       } 
}

}