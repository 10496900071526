.recommended-searches {
    .title {
        display: block;
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        color: var(--text-color);
        margin-bottom: 28px;
        padding: 18px 20px;
        border-radius: 7px;
        background: rgba(142, 197, 252, 0.08);
        font-family: var(--primary-font);
    }

    .searches {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: var(--font-16);
        font-weight: var(--weight-400);
        color: var(--text-color-light);
        padding-left: 20px;
        cursor: pointer;


        :hover {
            text-decoration: underline;
        }
    }

}