.home-filter {
    width: 70%;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    line-height: 36px;
    .group-filter-container{
        .ant-select-selection-overflow{
            flex-wrap: nowrap;
        }
        .ant-select-selection-item-content{
            max-width: 230px;
            
            @media (min-width: 720px) and (max-width:900px) {
            max-width: calc(230px - 110px);  
            }
            @media (min-width: 900px) and (max-width:1024px) {
            max-width: calc(230px - 140px);  
            }
             @media (min-width: 1024px) and (max-width:1200px) {
            max-width: calc(230px - 100px);  
            }
        }
    }

    .btns {
        display: flex;
        align-items: center;
        font-family: var(--primary-font);
        font-size: var(--font-14);
        font-weight: var(--weight-500);
        text-align: left;
        color: var(--black);
        line-height: 36px;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        height: 40px;

        .btn {
            padding: 3px 29px;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            cursor: pointer;
            background-color: var(--white);
            // min-width: 102px;
            background: rgba(255, 255, 255, 0.80);
            box-shadow: 0px 30px 60px -15px rgba(143, 144, 188, 0.15);
            backdrop-filter: blur(10px);

        }

        .active {
            background-color: var(--primary-color);
            color: var(--white);
        }
    }

    .filters {
        display: flex;
        justify-content: space-between;
        padding: 6px 0px;
        padding-inline-start: 18px;
        padding-inline-end: 6px;
        height: 72px;
        border-radius: 35px;
        border-start-start-radius: 0px;
        
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 30px 60px -15px rgba(143, 144, 188, 0.15);
        backdrop-filter: blur(10px);
        position: relative;

        .en {
            .item:not(:last-child):not(:nth-child(3)) {
                border-right: 1px solid var(--box-gray);
            }

        }

        .ar {
            .item:not(:last-child):not(:nth-child(3)) {
                border-left: 1px solid var(--box-gray);
            }

        }

        .selects {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .item {
                width: 100%;
                align-content: center;

                .with-margin.ant-select {
                    margin-bottom: 3%;
                }

                .ant-select-selector {
                    border: unset;
                    background: unset;
                    box-shadow: unset !important;

                    .ant-select-selection-placeholder {
                        color: var(--text-color);
                        font-size: var(--font-18);
                        font-weight: var(--weight-600);
                        display: inline-block;
                        white-space: pre;

                    }
                }

                .ant-select {
                    width: 100%;
                    // border-right: 1px solid var(--box-gray);
                }


            }
        }

    }

    .white-background {
        background-color: var(--white);
    }

    .find-btn {
        background: var(--primary-color);
        border: 1px solid var(--box-gray);
        border-radius: 35px;
        color: var(--white);
        width: 59px;
        height: 59px;
    }

    @media (max-width: 860px) {
        width: 90%;
    }

    @media (max-width:720px) {
        width: 95%;

        .btns {
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 2px;
            height: 25px;

            .btn {
                padding: 3px 15px;
                line-height: 25px;
                // height: 20px;
            }
        }

        .filters {
            height: 52px;
            padding: 3px 0px;
            padding-inline-start: 13px;
            padding-inline-end: 3px;

            .selects {
                .mobile-hide {
                    display: none;
                }
            }
        }


        .find-btn {
            border-radius: 50px;
            width: 50px;
            height: 46px;

            img {
                width: 20px;
            }
        }
    }
     @media (max-width: 600px) { 
        padding-top: 0;
    }

   
    
}

