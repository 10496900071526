.termsModal {
    
    .ant-modal-content {
        padding: 42px 30px 54px 30px !important;
        border-radius: 13px;
    }

    .housezText {
        font-size: var(--font-20);
        font-weight: var(--weight-500);
        color: var(--primary-color);
        font-family: var(--primary-font);
        text-align: center;
        margin: 0 0 19px 0 !important;
    }

    .descriptionText {
        font-size: var(--font-16);
        font-weight: var(--weight-400);
        color: var(--black);
        font-family: var(--primary-font);
        text-align: justify;
        margin: 0px 0px 13px 0px;
        line-height: 22px;


    }

    .titleText {
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        color: var(--primary-color);
        font-family: var(--primary-font);
        text-align: justify;
        margin: 0px 0px 2px 0px;
        line-height: 22px;


    }
}