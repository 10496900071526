.share-btns {
    display: flex;
    align-items: center;
    gap: 6px;

    img {

        &:hover {}
    }

    .menu-container {
        position: relative;

        // .menu-icon {
        //     cursor: pointer;
        //     width: 40px;
        //     height: 40px;
        // }

        .dropdown-menu {
            position: absolute;
            top: 30px;
            right: 10px;
            width: 100px;
            height: 68px;
            border-radius: 7px;
            background: var(--white);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
            z-index: 10;
            padding: 6px;

            .menu-item {
                display: flex;
                align-items: center;
                padding: 6px 12px;
                gap: 6px;

                .menu-icon {
                    width: 16px;
                    height: 16px;
                }

                span {
                    font-size: var(--font-14);
                    color: var(--primary-color);
                    font-weight: var(--weight-400);
                }
            }
        }
    }
}