.error-component {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;

    .error-message {
        font-size: 1.2rem;
        color: red;
    }
}