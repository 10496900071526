.areaCard {
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease;

    .image-wrapper {
        width: 100%;
        height: 318px;
        aspect-ratio: 256 / 319;
        border-radius: 14px;
        overflow: hidden;

        .property-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info-wrapper {
        padding: 15px 0;

        .area-name {
            color: var(--text-color);
            font-size: var(--font-18);
            font-weight: var(--weight-600);
            line-height: 22.8px;
            margin-bottom: 10px;
        }

        .properties-count {
            color: var(--text-color);
            font-size: var(--font-14);
            font-weight: var(--weight-400);
            line-height: 28.5px;
            margin: 0;
        }
    }
}

@media (max-width: 640px) {
    .areaCard {
        max-width: 100%;

        .image-wrapper {
            height: 318px;
            display: flex;
            justify-content: center;
        }
    }
}