
.ant-modal-root {
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.7); 
    }
}
.settings-modal {
    max-width: 381px;
    
    .modalCard{
        border-radius: 14px !important;
        overflow: hidden !important;
        padding: 0 !important;
    
    }
    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner::before {
        color: #fff !important;

    }

    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner::after {
        content: url('../../../../public/images/apts/icons/radioSign.svg');
        color: #fff !important;
        scale: 3;
        position: absolute;
        top: 5px;
        left: 12.5px;
        background-color: transparent;
        transition: all 0.2s;

    }

    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        box-shadow: none;

    }

    .ant-radio-wrapper .ant-radio-inner {
        width: 20px;
        height: 20px;
    }

    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner {
        border: none;
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        box-shadow: none;
    }

    .settingHeaderName {
        font-size: var(--font-20);
        font-weight: var(--weight-500);
        font-family: var(--primary-font);
        margin-bottom: var(--font-30);
        color: var(--black);
    }

    .headerAndradios {
        padding: 0px 20px 0px 20px;
    }

    .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: var(--font-18);
        padding-bottom: var(--font-40);

        .ant-radio-wrapper {
            font-size: var(--font-16);
            color: var(--black);
            font-family: var(--primary-font);
            font-weight: var(--weight-400);
            border-color: var(--primary-color);

            .ant-radio-inner {
                border-color: var(--radio-border-color);

                &:hover {
                    color: var(--black);
                    border-color: var(--primary-color);
                }
            }


        }

        .ant-radio-checked .ant-radio-inner {
            border-color: var(--primary-color);

        }
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
        padding: 0 !important;

        .ant-btn {
            font-size: 14px;

            &.ant-btn-primary {
                background-color: var(--primary-color);
                border-color: var(--primary-color);

                &:hover {
                    background-color: var(--primary-color);
                    border-color: var(--primary-color);
                }
            }
        }
    }

    .loading-spinner {
        text-align: center;
        padding: 20px;

        .ant-spin {
            color: var(--primary-color);
        }
    }
    .ant-btn-primary {
        color: var(--white) !important;
        background: var(--primary-color) !important;
        display: flex !important;
        font-size: var(--font-14);
        font-weight: var(--weight-700);
        font-family: var(--primary-font);
        width: 100%;
        border-radius: 25px;
        height: 40px;
    
    }
}

