.ant-breadcrumb{
    font-size: var(--font-18);
    font-weight: var(--weight-400);
    font-family: 'Roboto';
    .ant-breadcrumb-link{
        &.standard-text{
        color: #333333;
        margin: 0px;

        }
        margin: 0px 8px;
        display: flex
    }
    ol > li:nth-child(2) img {
    filter: brightness(0) saturate(100%) invert(30%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);}
     @media (max-width: 768px) {  
           display: none;
        }
        .ant-breadcrumb-link{
            [dir='rtl'] & {
                img{
                transform: rotateY(180deg);

            }
         }
            
        }
}

.ant-breadcrumb-separator{
    [dir='rtl'] & {
        transform: rotateY(180deg);
    }
}


