.fresh-sale {
    width: 100%;
    padding: 70px 108px;
    background-color: var(--off-white);

    h2 {
        margin: unset;
        font-size: var(--font-35);
        font-weight: var(--weight-600);
        margin-bottom: 60px;
    }

    .popular-residential {
        width: 100%;

        .slider {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            gap: 23px;

            .prev-btn,
            .next-btn {
                background-color: transparent;
                border: 0;
                border: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease;
                height: 319px;
                width: auto;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .swiper {
                width: 100%;
                margin: unset;
            }

            .swiper-slide {
                max-width: 256px;
            }
        }

    }
    .prev-btn, .next-btn {
        -webkit-tap-highlight-color: transparent;
      }

    @media (max-width: 700px) {
        padding: 12px !important;

        .popular-residential {
            .slider {
                gap: 40px !important;

                .swiper {
                    width: 100%;
                    margin: auto;
                }

                .prev-btn,
                .next-btn {
                    padding: 0;
                }
            }
        }
    }


    @media (max-width: 400px) {
        padding: 12px !important;

        .popular-residential {
            .slider {
                gap: 20px !important;

                .swiper {
                    width: 100%;
                    margin: auto;
                }

                .prev-btn,
                .next-btn {
                    padding: 0;
                }
            }
        }
    }
}