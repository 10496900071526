.agent-contact {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 20px;
    border-radius: 14px;
    border: 1px solid #F6F6F6;
    background: var(--white);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10);
    // width: 352px;
    height: 179px;
    font-family: var(--primary-font);

    .info-sec {
        display: flex;
        gap: 19px;

        img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        .profile {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            font-weight: var(--weight-500);
            gap: 3px;

            .name {
                color: var(--text-color);
                font-size: var(--font-18);
                display: block;
                width: calc(100% - 40px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .position {
                color: var(--text-color-light);
                font-size: var(--font-14);
                font-weight: var(--weight-400);
            }
        }

        .open-profile {
            width: 65%;
            display: flex;
            align-items: center;
            background: none;
            border: unset;
            padding: unset;
            font-size: var(--font-16);
            color: var(--primary-color);

            img {
                width: 24px;
            }
        }
    }

    .btns {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .view {
            color: #555;
            font-size: var(--font-18);
            text-decoration-line: underline;
            cursor: pointer;
            font-weight: var(--weight-400);
        }


        .contact {
            display: flex;
            align-items: center;
            gap: 8px;

            .contact-btn {
                border: unset;
                border-radius: 50%;
                background: var(--primary-color);
                width: 36px;
                height: 36px;
                padding: 9px;
                cursor: pointer;

                img {
                    width: 18px;
                    height: 18px;
                }
            }

        }
    }
}