.dropdown-saved-search-container {
    position: relative;
    display: inline-block;
    .closeIcon{
        position: absolute;
        right: 35px;
        top: 30px;

        img{
            cursor: pointer;
        }
    }
    .no-data-container {
        text-align: center;
        padding: 20px 40px;
    }

    .no-data-icon img {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
    }

    .no-data-container p {
        color: var(--text-color);
        font-size: var(--font-18);
        font-weight: var(--weight-500);
        font-family: var(--primary-font);
        line-height: 27px;
    }

    .dropdown-toggle {
        padding: 10px 16px;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        display: flex;
        gap: 10px;

        &:hover {
            color: var(--primary-color);
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: -70px;
        left: -120px;
        z-index: 10;
        background: var(--white);
        border-radius: 14px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        padding: 16px 22px 16px 22px;
        width: 337px;
        max-width: 337px;
        .dropdown-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            color: #333;
            width: 301px;
            margin-bottom: 12px;
           

            &:hover {
                color: var(--primary-color);

                .item-actions {
                    display: flex;
                    align-items: center;
                    gap: 0px;
                    transform: translateY(5px);
                }
            }

            .item-name {
                display: flex;
                align-items: center;
                white-space: normal !important;
                word-break: break-word;
            
            }

            // &:not(:last-child) {
            //     border-bottom: 2px solid var(--box-gray);
            // }


            .item-actions {
                display: none;
                gap: 0px;


                .edit-button,
                .delete-button {
                    background: none;
                    border: none;
                    cursor: pointer;
                   
                    padding: 4px;
                    height: 0px;
                    position: relative;
                    top: -10px;
                    width: 10px;

                    &:hover {
                        color: var(--primary-color);
                    }
                }

                .delete-button:hover {
                    color: red;
                }
            }
        }
    }
}