.contact-us-home-section {
    padding: 63px 108px 63px 108px;
    border-radius: 10px;
    margin: 20px 0;
    background: rgba(1, 186, 255, 0.03);



    .text-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0px;

        h2 {
            color: var(--black);
            font-size: var(--font-30);
            font-weight: var(--weight-700);
            font-family: var(--primary-font);
        }

        .request-button {
            width: 260px;
            height: 54px;
            padding: 14px 20px;
            justify-content: center;
            align-items: center;
            gap: 18px;
            border-radius: 37px;
            background-color: var(--primary-color);

            span {
                color: var(--white);
                text-align: center;
                font-size: var(--font-18);
                font-weight: var(--weight-600);
            }

            .arrow {
                font-size: var(--font-18);
                margin-inline-start: 5px;
            }
        }
    }


    @media (max-width:580px) {
        padding: 40px 20px 40px 15px;
        margin: 0px 0 !important;
  
        .text-container {
            flex-direction: column;
            margin-top: 0px;
            gap: 10px;
            margin: 0 !important;
            align-items: start;

            h2 {
                display: flex;
                justify-content: start !important;
                margin: 0 0 10px 0;

            }

            .request-button {
                width: 173px;
                height: 36px;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }

}