.RnowPlater {
    display: flex;
    flex-direction: column;
    gap: 61px;

    .mortgageDesc {
        display: flex;
        flex-direction: column;
        gap: 24px;
        position: relative;
        max-height: 800px;
        height: 100dvh !important;

        .uaeFirst {
            margin: 0;
            padding: 0;
            background-color: var(--primary-color);
            font-size: var(--font-65);
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
            border-radius: 13px;
            color: var(--white);
            text-align: center;
        }

        .digitalMortgage {
            margin: 0;
            padding: 0;
            font-size: var(--font-65);
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
            color: var(--primary-color);
            text-align: start;
        }

        .getTheBest {
            margin: 0;
            padding: 0;
            font-size: var(--font-24);
            font-weight: var(--weight-700);
            font-family: var(--primary-font);
            color: var(--text-color);
            text-align: start;
            max-width: 311px;
        }

        .pweredby {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            font-size: var(--font-12);
            font-weight: var(--weight-400);
            font-family: var(--primary-font);
            color: var(--black);
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }


    .RnowPlater-title {
        color: var(--black);
        font-size: var(--font-25);
        font-weight: var(--weight-600);
        position: relative;
        padding-bottom: 10px;
        font-family: var(--primary-font);

        &::after {
            content: '';
            display: block;
            width: 77px;
            height: 3px;
            background-color: var(--primary-color);
            position: absolute;
            bottom: -5px;
        }
    }

    

    .RnowPlater-iframe {
        width: 100%;
        height: 800px;
        min-height: 800px;
        border: 1px solid var(--box-gray-modal);
        border-radius: 13px;
        max-width: 863px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .RnowPlater-content {
        max-width: 863px;
        padding: 30px;
        border-radius: 21px;
        text-align: start;
        border: 1px solid var(--box-gray-modal);

        .section1 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        form {
            max-width: 863px;
        }

        h2 {
            font-size: var(--font-25);
            font-weight: var(--weight-600);
            color: var(--primary-color);
            font-family: var(--primary-font);
            padding-bottom: 24px;
        }

        .rent-section {
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            label {
                font-size: var(--font-18);
                font-weight: var(--weight-400);
                color: var(--black);
                font-family: var(--primary-font);

            }

            input {
                width: 100%;
                padding: 10px;
                border: 1px solid var(--box-gray-btn);
                border-radius: 13px;
                font-size: var(--font-16);
                color: var(--text-color);
                font-weight: var(--weight-600);
                font-family: var(--primary-font);
                box-sizing: border-box;
            }

        }

        .section2 {
            margin-top: 20px;
        }

        .installment-section {
            display: flex;
            flex-direction: column;
            gap: 25px;
            justify-content: center;
        }

        .installment-buttons {
            display: flex;
            justify-content: center;
            gap: 10px;
            overflow-x: auto;
            white-space: nowrap;
        }

        .installment-buttons .ant-btn {
            min-width: 26px;
            max-width: 36px;
            flex-shrink: 1;
        }


        h4 {
            font-size: var(--font-18);
            font-weight: var(--weight-400);
            color: var(--black);
            font-family: var(--primary-font);
            margin: 0;
        }

        .installment-buttons {
            display: flex;
            gap: 5px;
        }

        .installment-buttons button {
            background-color: #f6f6f6;
            border: none;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            font-size: var(--font-16);
            color: var(--text-color);
            font-family: var(--primary-font);
            cursor: pointer;
            transition: background-color 0.5s;

        }

        .installment-buttons button:hover,
        .installment-buttons button.active {
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            background-color: rgba(var(--primary-color), 0.5);

        }


        .monthly-rent {
            border: 1px solid var(--box-gray-modal);
            border-radius: 13px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 25px 30px 25px 30px;
            gap: 20px;

            p {
                font-size: var(--font-18);
                font-weight: var(--weight-400);
                color: var(--black);
                font-family: var(--primary-font);
                margin: 0;
            }

            h3 {
                font-size: var(--font-30);
                color: var(--primary-color);
                margin: 0;
                font-family: var(--primary-font);
                font-weight: var(--weight-600);
            }
        }

        .startedBtn {
            display: flex;
            flex-direction: column;
            gap: 6px;
            padding-top: 50px;



            .cta-button {
                display: flex;
                background-color: var(--primary-color);
                color: #fff;
                height: 50px;
                padding: 0px 34px 0px 34px;
                border: none;
                border-radius: 13px;
                font-size: var(--font-18);
                font-weight: var(--weight-600);
                font-family: var(--primary-font);
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #008dbf;
                }
            }

            .powered-by {
                font-size: var(--font-12);
                color: var(--black);
                text-align: center !important;
                font-family: var(--primary-font);
                font-weight: var(--weight-400);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;

            }

        }

    }
}



@media (max-width: 1200px) {
    .hideSection {
        display: none;
    }
}

@media (max-width: 880px) {
    .RnowPlater {
        .RnowPlater-iframe {
            border: 0px solid transparent !important;
            border-radius: 13px;
            max-width: 863px;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .monthly-rent {
            padding: 11px 9px 32px 9px !important;
        }

        .startedBtn {
            padding-top: 22px !important;
        }

    }
}