.reset-password-modal {
    max-width: 531px;

    h2 {
        font-size: var(--font-24);
        font-weight: var(--weight-500);
        margin-bottom: var(--font-16);
        color: var(--sign-in-text);
        font-family: var(--primary-font);
    }

    .EnterEmail {
        font-size: var(--font-12);
        color: var(--edit-profile-text);
        margin-bottom: 1.5rem;
        font-weight: var(--weight-500);
        font-family: var(--primary-font);
    }

    .ant-modal-content {
        border-radius: 12px;
        text-align: center;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        padding: 24px 17% 24px 17%;

    }

    .ant-modal-header {
        display: none; // Removes the default header
    }

    .ant-modal-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }



    p {
        font-size: 14px;
        color: #7a7a7a;
        margin-bottom: 24px;
    }

    .input-group {
        position: relative;
        margin-bottom: var(--font-20);
        text-align: start;

        span {
            color: var(--text-color);
            font-weight: var(--weight-400);
            font-size: var(--font-14);
            font-family: var(--primary-font);
        }

        .InputBoxes {
            border-radius: 25px;
            height: 46px;
            margin-top: 2px;
        }


        .icon {
            position: relative;


            color: var(--border-signIn-Icon);
            font-size: 16px;
        }

        // Input {
        //     width: 100%;
        //     padding: 0.75rem 1rem 0.75rem 2.5rem;
        //     border: 1px solid $border-color;
        //     font-size: 0.9rem;
        //     outline: none;
        //     transition: border-color 0.2s;

        //     &:focus {
        //         border-color: $primary-color;
        //     }
        // }
    }

    .login-button {
        width: 100%;
        height: 48px;
        background-color: var(--primary-color);
        color: #fff;
        border: none;
        border-radius: 25px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 36px;



        &:disabled {
            background-color: #dcdcdc;
            cursor: not-allowed;
        }
    }

    .error {
        font-size: 14px;
        color: #d9534f;
        margin-bottom: 16px;
    }

    .message {
        font-size: 14px;
        color: #5cb85c;
        margin-bottom: 16px;
    }
}