.apartments {
    display: flex;
    justify-items: center;
    flex-wrap: wrap;
    gap: 30px;

}

.apartments-ai {
    display: flex;
    justify-items: center;
    flex-wrap: wrap;
    gap: 10px;

}

.all {
    .item {
        // width: calc(33.333% - 35px);
        display: flex;
        justify-content: center;
    }
}

.not-all {
    .item {
        // width: calc(50% - 35px);
        display: flex;
        justify-content: center;
    }
}

@media (max-width:1023px) {

    .not-all {
        .item {
            width: 100%;
        }
    }

    .all {
        .item {
            width: 100%;
        }
    }
}

@media (max-width:680px) {
    .all {
        .item {
            width: 100%;
        }
    }
}

@media (max-width:385px) {

    .not-all {
        .item {
            width: 100%;
        }
    }
}