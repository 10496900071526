.description-section {
    color: var(--text-color-light);
    line-height: normal;
    font-size: var(--font-16);
    font-weight: var(--weight-400);
    transition: all 0.3s ease;
    margin-bottom: 40px;

    .description-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        position: relative;
        font-family: var(--primary-font);
        width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
        text-align: start;
        max-height: 40px;

        &.expanded {
            -webkit-line-clamp: unset;
            max-height: 1000px;
        }

        p {
            margin: 0;
            color: var(--text-color-light);
        }
    }

    .read-more {
        color: var(--text-color);
        cursor: pointer;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
            margin-left: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 0;

        .description-text {
            max-height: 70px;
        }

        .read-more {
            margin-top: 20px;
        }
    }
}