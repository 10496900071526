.navBar {
    position: relative;
    margin: 0;
    padding: 0;
    z-index: 15;
    border-bottom: 2px solid var(--box-gray);

    .no-scroll {
        overflow: hidden;
    }

    .settingbar-container {
        background: var(--sitting-header-bg);
    }

    .settingbar {
        padding: 10px 6%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .lang-setting {
            display: flex;
            gap: 10px;
            align-items: center;
        }

    }

    .lang {
        color: var(--text-color);
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }

    .setting {
        color: var(--text-color);
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .above-banner {
        position: absolute;
        z-index: 1;
        width: -webkit-fill-available;
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(2px);
        height: 94px;
    }

    .main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 34px 6%;
        font-size: var(--font-18);
        font-weight: var(--weight-500);
        margin: 0 auto;
        max-width: var(--max-width);

        .menuLinks {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;


        }
    }

    .active {
        background-color: var(--primary-color);
        border-radius: 5px;
        color: var(--white);
        padding: 6px 12px;
    }

    .Login {
        border-radius: 7px;
        background: var(--primary-color);
        color: var(--white);
        padding: 7px 13px;
        border: unset;
        cursor: pointer;
    }

    .burger {
        display: none;
        justify-content: space-between;
        align-items: center;
        padding: 3% 3%;

        @media (max-width: 820px) {
            .user-logged-in {
                display: none;
            }
        }

        .opacity {
            position: fixed;
            height: 100vh;
            z-index: 999;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.45);
        }

        .side-menu {
            position: fixed;
            background: var(--white);
            box-shadow: 3px 4px 10px 0px #0000001A;
            height: 100vh;
            z-index: 999;
            top: 0;
            left: 0;
            width: 84%;
            // padding: 5%;
            overflow: auto;

            [dir='rtl'] & {
                right: 0;
            }

            .ant-card {
                @media (min-width:390px) {
                    width: 100% !important;
                }
            }

            .logo-content {
                text-align: center;
                margin: 2rem 0px 0rem;
                padding-bottom: 0.5rem;
            }

            .mlogo {
                align-self: center;
            }

            .menu {
                padding: 3% 5% 5%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;

                .card-item {
                    font-weight: var(--weight-400);
                    font-size: var(--font-18);
                    font-family: var(--primary-font);
                    border-radius: 14px;
                    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);

                    .nav-link {
                        text-decoration: none;
                        color: #333;
                        font-weight: 500;
                        display: flex;
                        align-items: center;

                        gap: 10px;

                        p {
                            margin: 0;
                        }

                        &:hover {
                            color: var(--primary-color);
                        }

                        .userName {
                            color: rgba(1, 186, 255, 1);
                            font-weight: var(--weight-600);
                        }

                        .email {
                            font-weight: var(--weight-400);
                            font-size: var(--font-12);

                        }
                    }

                    .dropdown-saved-search-container {
                        padding: 0px;
                        width: 100%;
                        .dropdown-toggle {
                            padding: 0px;
                        }

                        .dropdown-menu {
                            left: 0px;
                            width: 250px;
                            max-width: 250px;
                            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);

                            .item-actions {
                                display: flex;
                                gap: 10px;

                                .edit-button,
                                .delete-button {
                                    width: 15px;
                                    height: 15px;
                                    padding: 0px;
                                    top: 0px;
                                }

                            }

                            .dropdown-item {
                                width: 100%;
                            }
                        }
                    }

                    .item-content {
                        padding: 14px 18px;

                        .danger {
                            color: var(--red) !important;
                        }

                        &.user {
                            .nav-link {
                                justify-content: space-between;

                            }
                        }

                    }

                    .item-content:last-child {
                        border-bottom: none
                    }

                    .ant-card-body {
                        padding: 0px;
                    }
                }

                .login {
                    color: rgba(1, 186, 255, 1);

                    &.card-item {
                        font-weight: var(--weight-600);
                        border-bottom: 0px;
                    }

                    .ant-card-body {
                        padding: 18px;
                    }
                }

                .close {
                    align-self: self-end;
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                }

            }
        }


    }

    .dividor {
        display: none;
        width: 100%;
        height: 1px;
        background-color: var(--text-color);
        margin-bottom: 20px;
    }

    .user-logged-in {
        display: flex;
        align-items: center;

        .nav-link {
            text-decoration: none;
            color: #333;
            font-weight: 500;
            display: flex;
            align-items: center;

            gap: 10px;

            p {
                margin: 0;
            }

            &:hover {
                color: var(--primary-color);
            }
        }

        .user-name {
            font-weight: bold;
        }

        button {
            margin-left: 10px;
        }
    }


    @media (max-width:820px) {
        .settingbar {
            display: none;
        }

        .main {
            display: none;
        }

        .burger {
            display: flex;
        }

        .dividor {
            display: block;
        }
    }
}

.ant-modal-close {
    color: var(--black) !important;

    position: absolute;

    margin-top: 19px;

    svg {
        width: 24px;
        height: 24px;
    }
}

.modalCard {
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 14px !important;
    overflow: hidden !important;
    padding: 0 !important;

}

.ant-modal-root {
    z-index: 9999;
}