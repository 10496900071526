.area-card {
    display: flex;
    align-items: center;
    padding: 11px;
    transition: box-shadow 0.3s ease;
    border-radius: 16px;
    border: 1px solid var(--box-gray);
    background: var(--white);
    height: 90px;
    gap: 14px;
    // &:hover {
    //     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    // }

    .image-wrapper {
        min-width: 70px;
        width: 70px;
        height: 70px;
        border-radius: 14px;
        overflow: hidden;

        .property-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .property-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;



        .area-name {
            color: var(--text-color);
            font-size: var(--font-18);
            font-weight: var(--weight-600);
            line-height: 20.4px;
            margin: 0;
            margin-bottom: 1px;
        }

        .property-count {
            color: var(--text-color);
            font-size: var(--font-14);
            font-weight: var(--weight-400);
            line-height: 24.7px;
            margin: 0;
        }
    }
}