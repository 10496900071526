.saveModal{
    max-width: 381px;
    

  
  
    .InputBoxes {
        border-radius: 25px;
        height: 46px;
        margin-top: 2px;
    }
    .login-button {
        width: 100%;
        padding: 0.75rem;
        background-color: var(--primary-color);
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: background-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 40px;
        margin-top: 20px;
        margin-bottom: 12px;
    }
    .settingHeaderName {
        font-size: var(--font-20);
        font-weight: var(--weight-500);
        font-family: var(--primary-font);
        margin-bottom: var(--font-16);
        color: var(--text-color);
    }
}
