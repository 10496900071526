.agent-profile {
    margin-bottom: 130px;

    .agent-header {
        position: relative;
        background: rgba(1, 186, 255, 0.03);

        .header-background {
            margin-bottom: -4px;
            width: 100%;
            height: 234px;
            object-fit: cover;
        }

        .drop-down-header {
            position: absolute;
            top: 3rem;
            padding: 0px 3rem;
            width: 100%;
            display: flex;
            justify-content: end;

            @media screen and (max-width: 820px) {
                top: 1.5rem;
                padding: 0px 1rem;
            }

        }

        .agent-details {
            margin: 0 6%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: auto;
            position: relative;

            .personal {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                position: absolute;
                transform: translateY(-35%);
                z-index: 1;


                .agent-photo-container {
                    width: 210px;
                    height: 210px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }

                .agent-photo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }

                .agent-photo-placeholder {
                    width: 100%;
                    height: 100%;
                    background-color: var(--white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 50px;
                    color: var(--black);
                    font-weight: bold;
                    border-radius: 50%;
                }

                h1 {
                    margin-top: 26px;
                    color: var(--black);
                    text-align: center;
                    font-family: var(--primary-font);
                    font-size: var(--font-25);
                    font-style: normal;
                    font-weight: var(--weight-600);
                    line-height: 1.2;
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

                p {
                    color: var(--text-color-light);
                    font-family: var(--primary-font);
                    font-size: var(--font-14);
                    font-weight: var(--weight-400);
                    line-height: 1.5;
                    margin: 5px;
                    display: -webkit-box;
                    width: 100%;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    white-space: normal;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .personal-hide {
                width: 34%;
            }

            .contact {
                width: 60%;
                padding: 0px 2rem;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-evenly;
                align-items: flex-start;
                text-wrap: nowrap;


                .contact-item {
                    width: 24%;
                    margin: 20px 0;
                    display: flex;
                    align-items: center;
                    color: var(--text-color);
                    font-family: var(--primary-font);
                    font-size: var(--font-18);
                    font-weight: var(--weight-400);
                    line-height: 1.5;
                    cursor: pointer;

                    img {
                        margin-inline-end: 16px;
                    }
                }

                .contact-item-small {
                    width: 2% !important;

                    img {
                        margin-inline-end: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            .header-background {
                height: 200px;
            }

            .agent-details {
                flex-direction: column;
                align-items: center;
                // padding: 33px 0;

                .personal {
                    position: relative;
                    width: 100%;
                    top: 0;
                    left: 0;
                    transform: translateY(-30%);
                    margin-bottom: -13%;

                    .agent-photo-container {
                        width: 32%;
                        height: 0;
                        padding-bottom: 32%;
                        position: relative;
                    }

                    .agent-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .agent-photo-placeholder {
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 40px;
                    }
                }

                .contact {
                    width: 100%;
                    padding: 0px 10px;

                    .contact-item {
                        img {
                            margin-inline-end: 8px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 480px) {
            .header-background {
                height: 155px;
            }

            .agent-details {

                .contact {
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .contact-item {
                        font-size: var(--font-16);
                        margin: 8px 0;
                        width: auto;

                        img {
                            margin-inline-end: 6px;
                        }
                    }
                }
            }
        }
    }

    .sort {
        justify-content: center;

        .sort-btn {
            font-size: var(--font-16);
            height: 38px;
        }

        // .sort-btn{
        //     padding: 7px 22px 7px 28px;
        //     width: auto;
        //     font-size: var(--font-18);
        // }
        // padding-inline-start: 14px;
    }

    @media (min-width: 768px) and (max-width: 1241px) {
        .sort {
            gap: 4px;
            flex-wrap: nowrap;
        }

        .RentOrSell {
            .btns {
                gap: 0px;
            }
        }
    }

    @media (min-width: 1290px) and (max-width: 1464px) {
        .filter-count-display-text {
            width: calc(92% - 100px);
        }
    }

    @media (min-width: 1464px) and (max-width: 1490px) {
        .filter-count-display-text {
            width: calc(100% - 2%);
        }
    }

    @media (min-width: 1500px) and (max-width: 1560px) {
        .filter-count-display-text {
            width: calc(100% - 130px);
        }
    }

    @media (min-width: 1560px) {
        .filter-count-display-text {
            width: 71%;
        }
    }

    @media (max-width: 768px) {
        .filter-count-display {
            flex-direction: column;
            margin-left: 0px;
            margin-right: 0px;
        }

        .filter-count-display-text {
            width: 100%;
        }

        .sort {
            gap: 10px;
            justify-content: start;
            margin-top: 25px;
            justify-content: space-between;

            .sort-btn {
                font-size: var(--font-16);
                height: 36.4px;
            }

            .RentOrSell {
                .btns {}
            }
        }
    }

    .agent-content {
        display: flex;
        justify-content: space-between;
        margin: 0 6%;

        @media (min-width: 1290px) and (max-width: 1464px) {
            .item {
                flex: 0 1 calc(50% - 30px);
                max-width: calc(50% - 30px);

                .apartment-card {
                    width: 100%;
                    min-width: 100%;
                }
            }
        }

        .left-content {
            width: 30%;
            margin-top: 160px;
            position: sticky;
            top: 25px;
            align-self: flex-start;

            .brokerage-section,
            .info-section,
            .description-section {
                background-color: var(--white);
                padding: 30px;
                border-radius: 14px;
                margin-bottom: 25px;
                box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10);
            }

            .brokerage-section {
                text-align: center;

                h3 {
                    color: var(--text-color);
                    font-family: var(--primary-font);
                    font-size: var(--font-20);
                    font-weight: var(--weight-600);
                    margin: 0 0 20px 0;
                }

                .brokerage-logo {
                    max-width: 128px;
                    max-height: 99px;
                    margin-bottom: 10px;
                }

                p {
                    color: var(--text-color);
                    font-family: var(--primary-font);
                    font-size: var(--font-18);
                    font-weight: var(--weight-400);
                    margin: 0 0 10px 0;
                }

                a {
                    color: var(--text-color-light);
                    font-family: var(--primary-font);
                    font-size: var(--font-14);
                    font-weight: var(--weight-400);
                    text-decoration-line: underline;

                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }

            .info-section {
                h3 {
                    color: var(--text-color);
                    font-family: var(--primary-font);
                    font-size: var(--font-20);
                    font-weight: var(--weight-600);
                    margin: 0 0 34px 0;
                }

                .info-item {
                    display: flex;
                    margin-bottom: 15px;

                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 28px;
                        // background: rgba(78, 146, 191, 0.05);
                        margin-inline-end: 21px;
                    }

                    .info-details {
                        span {
                            color: var(--text-color);
                            font-family: var(--primary-font);
                            font-size: var(--font-18);
                            font-weight: var(--weight-600);
                            display: block;
                            margin: 0 0 8px 0;

                            &:nth-child(2) {

                                color: var(--text-color-light);
                                font-family: var(--primary-font);
                                font-size: var(--font-18);
                                font-weight: var(--weight-400);
                            }
                        }
                    }
                }
            }

            .description-section {
                h3 {

                    color: var(--text-color);
                    font-family: var(--primary-font);
                    font-size: var(--font-20);
                    font-weight: var(--weight-600);
                    margin: 0 0 9px 0;
                }

                p {

                    color: var(--text-color);
                    text-align: justify;
                    font-family: var(--primary-font);
                    font-size: var(--font-18);
                    font-weight: var(--weight-400);
                    margin: 0 0 31px 0;
                }

                .areas-section,
                .properties-section,
                .areas-container {
                    color: var(--text-color);
                    font-size: var(--font-20);
                    font-weight: var(--weight-600);
                    margin: 0 0 17px 0;

                    span {
                        text-align: start;
                        font-size: var(--font-18);
                        font-weight: var(--weight-400);
                    }
                }

                .areas-container {
                    display: flex;
                    align-items: flex-start;
                }

                .areas-list {
                    padding-inline-start: 7px;
                    line-height: 22px;
                }

            }
        }

        .right-content {
            width: 66%;
            display: flex;
            flex-wrap: wrap;

            .props {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 54px;
                width: 100%;

                .apts {
                    width: 100%;

                    .seeMoreBtn {
                        background-color: transparent;
                        font-weight: var(--weight-500);
                        font-size: var(--font-18);
                        color: var(--primary-color);
                        margin: 0 auto;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        padding: 80px 0px 77px 0px;
                        text-decoration: underline;

                    }
                }
            }

            .loader,
            .loading,
            .error-message {
                text-align: center;
                font-size: 1.2rem;
                color: #666;
                margin: 20px 0;
                // height: 100%;
                width: 100%;
            }

            .error-message {
                color: red;
            }

            .no-properties {
                text-align: center;
                font-size: 1.1rem;
                color: #999;
                margin-top: 20px;
            }
        }

        @media (max-width:768px) {
            flex-direction: column;

            .right-content {
                .props {
                    .apts {
                        .seeMoreBtn {
                            padding: 30px 0px 44px 0px;
                        }
                    }
                }
            }

            .left-content {
                position: unset;
                top: unset;
                align-self: unset;
            }

            .left-content,
            .right-content {
                width: 100%;
                margin-top: 20px;
            }

            .right-content {
                margin-top: 0px;

                .props {
                    flex-direction: column;
                    gap: 20px;

                    .apts {
                        width: 100%;
                    }
                }
            }
        }
    }
}