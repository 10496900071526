.footers {
    margin-top: auto;
    background: var(--footer-bg);

    .footer {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        padding: 48px 7%;
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        border-bottom: 1px solid var(--box-gray);
        position: relative;

        .devidor {
            position: absolute;
            width: 1px;
            height: 100%;
            inset-inline-start: 35%;
            background-color: var(--box-gray);
        }

        .logo {
            width: 35%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-size: var(--font-16);
            font-weight: var(--weight-400);
            color: #000;
            padding-inline-end: 7%;
            align-items: start;

            .download-title {
                margin-top: 14px;
            }

            .download-btn {
                display: flex;
                gap: 24px;

                img {
                    width: 50%;
                    max-width: 136px;
                }
            }


        }

        .footer-menu {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 20px;
            width: 80%;
            padding-inline-start: 7%;


            .menuLinks {
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: start;
                color: #000;

                .newsletter-container {}

                .newsletter-title {
                    color: var(--primary-color);
                    font-size: var(--font-18);
                    font-weight: var(--weight-700);
                    font-family: var(--primary-font);
                    margin: 0;
                    padding: 0px 0px 14px 0px;
                }

                .socialMedia-title {
                    color: var(--primary-color);
                    font-size: var(--font-18);
                    font-weight: var(--weight-700);
                    font-family: var(--primary-font);
                    margin: 0;
                    padding: 0px 0px 0px 0px;
                }

                .newsletter-input-container {
                    margin-inline-end: 4px;
                }

                .newsletter-input {
                    border-radius: 30px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    width: 264px;
                    margin-bottom: 12px !important;

                }

                .subscribe-button {
                    background-color: var(--primary-color);
                    border-radius: 30px;
                    width: 115px;
                    min-width: 115px;
                    height: 48px;
                    box-shadow: unset;
                }


                .title {
                    font-weight: var(--weight-700);
                    font-size: var(--font-18);
                }


                .links {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    justify-content: center;
                    font-size: var(--font-14);
                    font-weight: var(--weight-400);
                    white-space: nowrap;
                    font-family: (var(--primary-font));

                    .item {
                        display: flex;
                        gap: 14px;
                        align-items: center;

                        .clickable-link {
                            display: flex;
                            align-items: center;
                            gap: 14px;
                            color: inherit;
                            text-decoration: none;

                            img {
                                width: 24px;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

            }
        }


        .social-links {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 15px;

            .title {
                font-weight: var(--weight-700);
                font-size: var(--font-18);
            }

            .link {
                width: 18px;

                img {
                    width: 100%;
                    display: block;
                }
            }
        }


    }

    .terms {
        padding: 26px;
        color: var(--text-color-light);
        font-size: var(--font-16);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 1200px) {
        .newsletter-input {
            width: 200px !important;

        }


    }

    @media (max-width: 1100px) {
        .links {
            white-space: wrap !important;
        }
    }

    @media (max-width: 1000px) {
        .links {
            white-space: nowrap !important;
        }

        .footer {
            flex-direction: column;
            align-items: start;
            gap: 40px;

            .logo {
                width: 100%;


            }

            .devidor {
                display: none;
            }

            .footer-menu {
                flex-direction: column;
                gap: 40px;
                width: unset;
                padding: unset;
            }
        }


    }
}