.RentOrSell {
    .btns {
        display: flex;
        gap: 10px;
        border:1px solid rgba(231, 231, 231, 1);
        border-radius: 63px;
        padding: 2px;
    }

    button {
        border-radius: 63px;
        font-size: var(--font-16);
        font-family: var(--primary-font);
        cursor: pointer;
        // border: 1px solid var(--box-gray-btn);
        border:0px;
        transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
        outline: none;
        box-shadow: none;
    }

    .RentBtn {
        background-color: var(--white);
        // border: 1px solid var(--box-gray-btn);
        color: var(--text-color-light);
        padding: 7px 35px;
        transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    }

    .BuyBtn {
        background-color: var(--white);
        // border: 1px solid var(--box-gray-btn);
        color: var(--text-color-light);
        padding: 7px 39px;
        transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    }

    .active {
        background-color: var(--primary-color);
        border: unset;
        color: var(--white);
    }

}

