.group-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &.property-filter-type{
          @media (max-width: 500px) {
            .dropdown-overlay {
                width: fit-content;
                transform: translateX(0%);
            }

            
        }
    }
    &.beds-and-baths{
        [dir="rtl"] & {
            @media (max-width: 500px) {
                .dropdown-overlay {
                transform: translateX(35%);}
            }
        }
    }
 
    .dropdown-overlay {
        position: absolute;
        top: 55px;
        left: 0; 
        right: 0;
        margin: auto; 
        z-index: 100;
        @media (max-width: 500px) {
            width: fit-content;
            transform: translateX(-35%);
        }      
      
    }

    .dropdown-content {
        padding: 22px;
        padding-bottom: 0;
        width: 350px;
        max-height: 350px;
        border-radius: 14px;
        background: rgba(255, 255, 255, 0.9);
        /* More opacity to differentiate */
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        font-size: 16px;
        gap: 20px;

        backdrop-filter: blur(5px);
        /* Different blur value */
        z-index: 2;
        will-change: backdrop-filter;
         @media (max-width: 500px) {
            width: 97%;
         }
    }

    .ant-checkbox-group {
        width: 100%;
        height: 210px;
    }

    .filter-options {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 16px;
        border-radius: 14px;
        // background: var(--footer-bg);
    }
    &.property-filter-background{
        .filter-options {
            background: var(--footer-bg);

        }
    }
    .features{
        .ant-checkbox-group{
            background: var(--footer-bg);
            border-radius: 14px;
        }
    }

    .checkbox-option {
        width: 100%;
        display: inline-flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;

        color: var(--text-color);
        font-family: var(--primary-font);
        font-size: var(--font-14);
        font-weight: var(--weight-400);

        span {
            padding: 0;
        }
    }

    .circular-checkbox .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-radius: 12px;
        border: 1px solid #E7E7E7;
    }

    .filter-buttons {
        height: 30%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 7px;

        position: sticky;
        padding-top: 12px;
        padding-bottom: 22px;
        bottom: 0;
        // background-color: var(--white);
    }

    .reset-btn {
        max-width: 160px;
        height: 48px;
        padding: 5px 24px 8px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 25px;
        border: 1px solid #E7E7E7;
        background: var(--white);
        color: var(--primary-color);
        width: 50%;
    }

    .apply-btn {
        max-width: 160px;
        height: 48px;
        padding: 5px 24px 8px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 25px;
        background: var(--primary-color);
        color: var(--white);
        width: 50%;
    }

    .custom-select {
        .ant-select-focused .custom-arrow-icon {
            transform: unset;
        }
    //     @media (max-width: 480px) {
    //         .ant-select-selector {
    //             padding: 9px 10px;
    //         }
    // }
    }

    .custom-select.dropdown-open {
        .custom-arrow-icon {
            transform: rotate(180deg);
        }
    }

   
}

.white-background {
    background-color: var(--white) !important;
}

.filter-section {

    h3 {
        margin: 0;
        margin-bottom: 10px;
        color: var(--text-color);
        font-family: var(--primary-font);
        font-size: var(--font-18);
        font-weight: var(--weight-500);
    }
}

.horizontal-select {
    .filter-section {
        .ant-checkbox-group {
            width: 100%;
            height: fit-content;
        }

        .filter-options {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            column-gap: 5px;
            justify-content: flex-start;
            align-items: center;
            padding: 0px;
            margin: 0;
            // background-color: var(--white);
            height: fit-content;
        }

        .checkbox-option {
            border-radius: 26px;
            width: fit-content;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.2s ease;
            background: #F6F6F6;
            padding: 5px;

            .ant-checkbox-inner {
                display: none;
            }

            .ant-checkbox-input {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }

        .checkbox-option.ant-checkbox-wrapper-checked {
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            background: var(--filter-light-blue-background);
        }
    }

}