.company-card {
    border-radius: 14px;
    background: var(--white);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    width: 286px;
    max-width: 286px;
    min-width: 286px;
    height: 378px;
    display: flex;
    flex-direction: column;
    font-weight: var(--weight-600);
    font-size: var(--font-18);
    font-family: var(--primary-font);
    cursor: pointer;

    .img {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        padding: 20px;
        height: 209px;

        .company-img-container {
            width: 100px;
            height: 80px;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .company-img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }

        .name {
            font-size: var(--font-18) ;
            padding-top: 35px;
            white-space: nowrap;
            overflow: hidden;
            // text-overflow: ellipsis;
            max-width: 100%;
        }

        .address {
            padding-top: 5px;
            font-size: var(--font-14);
            color: var(--text-color-light);
            font-weight: var(--weight-500);
            white-space: nowrap;
            overflow: hidden;
            max-width: 80%;
        }

    }

    .devider {
        width: 100%;
        height: 1px;
        background-color: var(--box-gray);
    }

    .details {
        padding: 0 36px 10px 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 110px;

        .field {
            color: var(--text-color);
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: var(--font-16);
            font-weight: var(--weight-600);

            .value {
                font-weight: var(--weight-400);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 35%;
            }
        }

    }

    .open {
        font-family: var(--primary-font);
        font-size: var(--font-16);
        color: var(--white);
        background-color: var(--primary-color);
        border-radius: 35px;
        border: unset;
        cursor: pointer;
        padding: 8px 0;
        margin: 0 19px;
    }

    @media (max-width: 520px) {
        width: 90% !important;
    }
}