.info-section-details {
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    .qr-code-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .qr-code {
            width: 91px;
            height: 91px;
            margin-bottom: 22px;
        }

        .label {
            font-size: var(--font-16);
            font-weight: var(--weight-500);
            color: var(--text-color);
        }
    }
}