.download {
    margin: 0 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .img {
        max-width: 50%;
        // width: 728px;
        max-height: 558px;
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 600px;
        gap: 50px;
        color: var(--text-color);

        .title {


            h2 {
                margin: unset;
                font-size: var(--font-48);
                font-weight: var(--weight-600);
                white-space: wrap;
                margin-bottom: 8px;

            }

            .description {
                font-size: var(--font-18);
                font-weight: var(--weight-400);
            }
        }

        .download-btns {
            display: flex;
            flex-direction: column;
            gap: 14px;
            font-size: var(--font-18);
            font-weight: var(--weight-500);

            .btns {
                display: flex;
                gap: 14px;

                button {
                    border: unset;
                    border-radius: 14px;
                    padding: unset;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width:580px) {
        flex-direction: column-reverse;
        gap: 50px;

        .img {
            max-width: 100%;
        }

        .details {
            gap: 20px;

            .title {

                h2 {
                    margin-bottom: 8px;

                }
            }
        }
    }
}