.sort-container {
    position: relative;
}

.tooltip-container {
    position: relative; 
}

.tooltip {
    position: absolute;
    bottom: 40px; 
    left: 50%;
    transform: translateX(-50%);
    background-color: #333; 
    color: #fff; 
    padding: 5px 10px; 
    border-radius: 4px; 
    white-space: nowrap; 
    opacity: 0; 
    transition: opacity 0.2s; 
    visibility: hidden; 
    z-index: 10; 
}

.tooltip-container:hover .tooltip {
    opacity: 1; 
    visibility: visible; 
}