.editProfileModal {
    max-width: 381px;

    .editProfile {

        .input-group {
            position: relative;
            margin-bottom: var(--font-20);
            text-align: start;

            span {
                color: var(--text-color);
                font-weight: var(--weight-400);
                font-size: var(--font-14);
                font-family: var(--primary-font);
            }

            .InputBoxes {
                border-radius: 25px;
                height: 46px;
                margin-top: 2px;
            }


            .icon {
                position: relative;


                color: var(--border-signIn-Icon);
                font-size: 16px;
            }

            // Input {
            //     width: 100%;
            //     padding: 0.75rem 1rem 0.75rem 2.5rem;
            //     border: 1px solid $border-color;
            //     font-size: 0.9rem;
            //     outline: none;
            //     transition: border-color 0.2s;

            //     &:focus {
            //         border-color: $primary-color;
            //     }
            // }
        }

        h2 {
            font-size: var(--font-20);
            font-weight: var(--weight-500);
            margin-bottom: var(--font-20);
            color: var(--edit-profile-text);
            font-family: var(--primary-font);
        }

        .apply-button {
            width: 100%;
            padding: 0.75rem;
            background-color: var(--primary-color);
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            border: none;
            border-radius: 25px;
            cursor: pointer;
            transition: background-color 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            height: 46px;

        }
    }
}