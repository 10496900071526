.location-search-filter.explore-location-filter {
    max-width: 408px;
    width: 100%;
    height: 48px;
    &.custom-select{
        flex: none;
    }
    
    position: relative;

    @media (max-width: 1250px) {
        flex: 1 0 calc(70% - 10px);
        max-width: -webkit-fill-available;

    }

    @media (max-width: 480px) {
        height: 35px;
    }
    .location-search-input {
        height: 100%;
         @media (max-width: 750px) {
            .css-1fdsijx-ValueContainer{
                padding-inline-start: 12px !important;
                padding: 0;
            }

        }
        
        .css-13cymwt-control,
        .css-t3ipsp-control {
            width: 100%;
            height: 100%;
            border-radius: 30px;
            border: 1px solid var(--box-gray);
            box-shadow: none;

            svg {
                display: none;
                transition: transform 0.3s ease, fill 0.3s ease;
            }
        }

        .css-t3ipsp-control {
            border-color: var(--primary-color) !important;
        }

        .css-1u9des2-indicatorSeparator {
            display: none;
        }

        .css-1fdsijx-ValueContainer {
            padding-inline-start: 20px;
        }

        .css-1jqq78o-placeholder {
            color: var(--text-color-light);
            font-weight: var(--weight-500);


            @media (max-width: 480px) {
                padding-inline-start: 0;
                font-size: var(--font-14);
            }
        }

        :has(.css-1dimb5e-singleValue) {

            .css-1xc3v61-indicatorContainer,
            .css-15lsz6c-indicatorContainer {
                color: var(--primary-color) !important;
            }
        }
    }

    .location-search-input:has(.css-1nmdiq5-menu) {

        svg {
            transform: rotate(180deg);

            path {
                stroke: var(--primary-color);
            }
        }
    }

    .location-search-input:not(:has(.css-1jqq78o-placeholder)) {

        .css-13cymwt-control,
        .css-t3ipsp-control {
            border-color: var(--primary-color) !important;
            background: var(--filter-light-blue-background);

            .css-1dimb5e-singleValue {
                color: var(--primary-color) !important;
            }
        }

        path {
            stroke: var(--primary-color);
        }

        .ant-select-arrow {
            color: var(--primary-color);

            path {
                stroke: var(--primary-color);
            }
        }
    }

}

.location-search-filter.home-location-filter {

    color: var(--text-color);
    font-weight: var(--weight-600);
    font-size: var(--font-18);

    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.5714285714285714;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 1%;


    .location-search-input {
        // display: inline-block;
        // white-space: pre;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            width: 100%;
            height: 100%;
            border: unset;
            background: unset;
            box-shadow: unset !important;

            svg {
                display: none;
            }
        }

        .css-1u9des2-indicatorSeparator {
            display: none;
        }

        .css-1jqq78o-placeholder {
            color: var(--text-color);

            @media (max-width: 480px) {
                padding-inline-start: 0;
                font-size: var(--font-14);
            }
        }
    }
}

.location-search-icon,
.location-clear-icon {
    position: absolute;
    inset-inline-end: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    color: var(--primary-color);
}