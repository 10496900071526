.action-btns {
    display: flex;
    align-items: center;
    gap: 6px;
    -webkit-tap-highlight-color: transparent;
    .favorite-btn {
        transition: transform 0.3s ease, filter 0.3s ease;

        &.empty {
            filter: none;
            
        }

        &:active {
            transform: scale(1.2);
        }

        &.pulse {
            &:active {
                animation: pulse-animation 0.3s ease-in-out;
            }
        }
    }

    @keyframes pulse-animation {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);
        }
    }

}