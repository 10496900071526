.image-gallery-section {
    .img-container {
        display: flex;
        gap: 21px;
        max-height: 600px;

        .main-img {
            width: 70%;
            min-width: 70%;

            border-radius: 14px;
            object-fit: cover;
        }

        .thumb-img-container {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 21px;

            .item {
                flex-grow: 1;
                height: 261px;
                border-radius: 7px;
                overflow: hidden;
                object-fit: cover;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: inherit;
                }
            }

            .overlay {
                position: relative;
                cursor: pointer;

                span {
                    background-color: rgba(0, 0, 0, 0.3);
                    color: var(--white);
                    position: absolute;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: var(--font-18);
                    font-weight: var(--weight-400);
                    border-radius: inherit;
                }
            }
        }
    }

    .imageGallery {
        position: fixed;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background: rgba(34, 34, 34, .9);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;


        .popup-gallery {
            width: 65%;

            .closeButton {
                position: absolute;
                top: 6px;
                right: 6px;
                cursor: pointer;
                color: var(--white);
                font-size: var(--font-30);
            }

        }
    }

}