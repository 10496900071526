.agents {
    margin: 4% 6% 130px 6%;

    .agents-grid {
        display: flex;
        justify-items: center;
        flex-wrap: wrap;
        gap: 0px;
        row-gap: 28px;

        .item {
            display: flex;
            justify-content: center;
            width: calc(25% - 20px);
        }

    }


    @media (max-width: 1321px) {
        .agents-grid {
            .item {
                width: calc(33.333% - 20px);

            }
        }

    }

    @media (max-width: 1000px) {
        .agents-grid {
            .item {
                width: calc(50% - 20px);
            }
        }

    }

    @media (max-width: 660px) {
        .agents-grid {
            .item {
                width: 100%;
            }
        }
    }
}