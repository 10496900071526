.advertising-card {
    height: 323px;
    width: 100%;
    overflow: hidden;
    border-radius: 14px;
    border: 1px solid var(--text-color-light);
    background: var(--white);
    max-width: 400px;
    align-self: center;

    a {
        display: block;
        width: 100%;
        height: 100%;

        img.advertising-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}