.info-section-details {
    margin-bottom: 40px;

    .section-title {
        color: var(--black);
        font-size: var(--font-25);
        font-weight: var(--weight-600);
        padding-bottom: 10px;
        margin-bottom: 37px;
        position: relative;
        display: inline-block;

        &::after {
            content: '';
            display: block;
            width: 77px;
            height: 3px;
            background-color: var(--primary-color);
            position: absolute;
            bottom: 0;
        }
    }

    .items-grid {
        display: grid;
        row-gap: 16px;
        align-items: flex-start;

        @media (max-width: 768px) {
            gap: 30px;
            grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 431px) {
            gap: 16px;

            &.small-grid {
                grid-template-columns: repeat(1, 1fr) !important;
            }
        }
    }

    .info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        font-size: 1rem;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 16px;

        &.last-in-column {
            border-bottom: none;
            padding-bottom: 5px;
        }

        .label {
            flex: 1.5;
            color: var(--text-color);
            font-size: var(--font-18);
            font-weight: var(--weight-500);
            
        }

        .value {
            flex: 1;
            white-space: nowrap;
            color: var(--text-color);
            font-size: var(--font-18);
            font-weight: var(--weight-400);
        }

        .icon {
            width: 25px;
            height: 25px;
            margin-inline-end: 2px;
        }

        .iconLayout {
            max-width: 279px;
            width: 100%;
            max-height: 226px;
            height: 100%;
            margin-inline-end: 2px;
            border-radius: 18px !important;

        }
    }


    @media (max-width: 768px) {
        width: 100%;

        .section-title {
            margin-bottom: 25px;
        }
    }

    @media (max-width: 425px) {
        width: 100%;

        .info-item {
            gap: 14px;

            .icon {
                width: 20px;
                height: 20px;
                margin-inline-end: 0px;
            }
        }
    }
}

.video-title {
    color: var(--black);
    font-size: var(--font-30);
    font-weight: var(--weight-600);
    position: relative;
    padding-bottom: 10px;

    &::after {
        content: '';
        display: block;
        width: 77px;
        height: 3px;
        background-color: var(--primary-color);
        position: absolute;
        bottom: -5px;
    }
}

.swiper-container {
    position: relative;
}