.apartment-card {
    border-radius: 14px;
    background: var(--white);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
    width: 410px;
    max-width: 410px;
    min-width: 394px;
    height: 552px;
    font-weight: var(--weight-400);
    font-size: var(--font-16);
    color: var(--text-color);

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;

        .img-gallery {
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            position: relative;
            width: 100%;
            height: 252px;

            .image-gallery-image {
                border-top-left-radius: 14px;
                border-top-right-radius: 14px;
                width: 100%;
                height: 252px;
                object-fit: cover;
            }

            .image-gallery-bullet {
                background-color: var(--active-filter-btn-bg);
                padding: 2px;
                box-shadow: unset;
            }

            .image-gallery-bullet:hover {
                background: var(--primary-color);
                border: 1px solid var(--primary-color);
            }

            .image-gallery-bullet.active {
                transform: scale(1.2);
                border: 1px solid var(--primary-color);
                background: var(--primary-color);
            }

            .image-gallery-slide-wrapper .image-gallery-left-nav,
            .image-gallery-slide-wrapper .image-gallery-right-nav {
                opacity: 0;
            }

            .image-gallery-slide-wrapper:hover .image-gallery-left-nav,
            .image-gallery-slide-wrapper:hover .image-gallery-right-nav {
                opacity: 1;
            }

            .image-gallery-svg {
                width: 15px;
            }

            .image-gallery-icon {
                filter: unset;
            }

            .love {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 4;
                display: flex;
                gap: 6px;

                .lf-btn {
                    cursor: pointer;
                    transition: transform 0.3s ease, filter 0.3s ease;

                    &.empty {
                        filter: none;
                    }

                    &:active {
                        transform: scale(1.2);
                    }

                    &.pulse {
                        &:active {
                            animation: pulse-animation 0.3s ease-in-out;
                        }
                    }
                }
            }

            .company {
                position: absolute;
                left: 20px;
                bottom: 13px;
                display: flex;
                align-items: center;
                gap: 4px;

                .company-img {
                    // width: 44px;
                    // height: 44px;
                    // border-radius: 35px;
                    // background-color: var(--white);
                    // align-content: center;
                    // padding: 8px;

                    img {
                        width: 100%;
                        max-width: 50px;
                        display: block;
                    }
                }

                .company-info {
                    display: flex;
                    flex-direction: column;
                    color: var(--white);
                    font-size: var(--font-12);
                    font-weight: var(--weight-500);

                    .view {
                        font-size: var(--font-10);
                        font-weight: var(--weight-400);
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

            }

            .count {
                position: absolute;
                left: 20px;
                bottom: 20px;
                align-items: center;
                // width: 40px;
                height: 19px;
                border-radius: 3px;
                background: rgba(0, 0, 0, 0.55);
                padding: 0px 8px;

                span {
                    color: var(--white);
                    font-size: var(--font-10);
                    font-weight: var(--weight-500);
                }
            }

            .image-gallery-index {
                left: 20px;
                right: unset;
                top: unset;
                bottom: 20px;
                align-items: center;
                // width: 40px;
                height: 19px;
                border-radius: 3px;
                background: rgba(0, 0, 0, 0.55);
                padding: 0px 8px;

                span {
                    color: var(--white);
                    font-size: var(--font-10);
                    font-weight: var(--weight-500);
                }

                @media (max-width: 768px) {
                    font-size: unset;
                }
            }
        }

        .details {
            width: -webkit-fill-available;
            height: 300px;

            .detail {
                width: -webkit-fill-available;
                height: 226px;
                padding: 20px;
                padding-bottom: 31px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 8px;
                cursor: pointer;


                .listed {
                    color: var(--text-color-light);
                    font-size: 12px;
                }

                .price {
                    font-size: var(--font-25);
                    font-weight: var(--weight-500);
                    margin-bottom: 4px;
                }

                .location {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    span {
                        display: inline-block;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 1;
                    }
                }

                .location-description {
                    font-size: var(--font-13);
                    color: var(--text-color-light);
                    margin-bottom: 12px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 1;
                }

                .description {
                    display: flex;
                    gap: 10px;
                    flex-direction: row;
                    align-items: start;
                    font-size: var(--font-12);

                    .item {
                        width: 40%;
                        display: flex;
                        flex-direction: column;
                        gap: 13px;
                    }

                    span {
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .beds-baths {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 12px;
                    }
                }
            }

            .btns {
                border-top: 1px solid var(--box-gray);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 15px 20px;
                height: 74px;

                .agent {
                    display: flex;
                    gap: 9px;
                    align-items: center;

                    img {
                        width: 44px;
                        height: 44px;
                        border-radius: 35px;
                        object-fit: cover;
                    }

                    .agent-info {
                        display: flex;
                        flex-direction: column;
                        color: #000;
                        font-size: var(--font-16);
                        font-weight: var(--weight-400);
                        gap: 2px;

                        .agent-name {
                            display: block;
                            width: 16ch;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap !important;
                        }

                        .view {
                            color: var(--text-color-light);
                            font-size: var(--font-10);
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                .contact {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .contact-btn {
                        border: unset;
                        border-radius: 50%;
                        background: var(--primary-color);
                        width: 36px;
                        height: 36px;
                        padding: 9px;
                        cursor: pointer;

                        img {
                            width: 18px;
                        }
                    }

                }

                .company {
                    max-width: 50px;
                }
            }

        }
    }

    @media (max-width:430px) {


        .agent-name {
            display: block;
            width: 12ch !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap !important;

        }

    }


    @media (max-width:330px) {
        .agent-name {
            width: 7ch !important;

        }
    }

    // @media (max-width:620px) {
    //     .container {
    //         flex-direction: column;
    //         align-items: unset;

    //         .img-gallery {
    //             .image-gallery-image {
    //                 width: 100%;
    //             }
    //         }


    //         .details {
    //             padding-top: unset;

    //             .description {

    //                 span {
    //                     max-width: 100px;

    //                 }
    //             }

    //             .company {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}

@media (max-width:500px) {
    .apartment-card {
        width: 100%;
        max-width: unset !important;
        min-width: unset !important;
    }
}