.emailModal {
    .span {
        color: var(--text-color);
        font-weight: var(--weight-400);
        font-size: var(--font-14) !important;
        font-family: var(--primary-font);
    }

    .ant-checkbox-wrapper .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid var(--radio-border-color);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
    }

    .ant-checkbox-wrapper .ant-checkbox-input:checked+.ant-checkbox-inner:hover {
        background-color: transparent;

    }

    .ant-checkbox-wrapper .ant-checkbox-input:checked+.ant-checkbox-inner {
        background-color: var(--white);
        border: 2px solid var(--sign-in-gray) !important;
        box-shadow: none;
    }

    .ant-checkbox-wrapper .ant-checkbox-input:checked+.ant-checkbox-inner::before {
        content: url('../../../public/images/apts/icons/emailCheckBox.svg');
        display: block;
        position: absolute;
        top: 5%;
        left: 30%;
    }

    .ant-checkbox-wrapper .ant-checkbox-input:checked+.ant-checkbox-inner::after {
        content: url('../../../public/images/apts/icons/radioSign.svg');
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
    }

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: var(--font-18);
        padding-bottom: var(--font-18);

        .ant-checkbox-wrapper {
            font-size: var(--font-16);
            color: var(--black);
            font-family: var(--primary-font);
            font-weight: var(--weight-400);

            .ant-checkbox-input:checked+.ant-checkbox-inner:hover {
                border-color: var(--primary-color);
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                border-color: var(--primary-color);
            }
        }
    }

    .ant-modal-content {
        border-radius: 13px;
        padding: 38px 50px 20px 50px;
    }

    .emailme {
        color: var(--sign-in-gray);
        font-size: var(--font-12);
        font-family: var(--primary-font);
        font-weight: var(--weight-400);
        display: flex;
        align-items: center;
    }

    .send-button {
        max-width: 198px;
        padding: 0.75rem;
        background-color: var(--primary-color);
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: background-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 0 auto;
        height: 48px;


        &:disabled {
            background-color: #dcdcdc;
            cursor: not-allowed;
        }
    }

    .icon {
        position: relative;
        color: var(--border-signIn-Icon);
        font-size: 16px;
    }

    .email {
        color: var(--primary-color);
        font-family: var(--primary-font);
        font-size: var(--font-25);
        font-weight: var(--weight-500);
        margin: 0;

    }

    .emailDesc {
        color: var(--primary-color);
        font-family: var(--primary-font);
        font-size: var(--font-15);
        font-weight: var(--weight-400);

    }

    .input-group {
        position: relative;
        margin-bottom: var(--font-20);
        text-align: start;

        span {
            color: var(--text-color);
            font-weight: var(--weight-400);
            font-size: var(--font-14) !important;
            font-family: var(--primary-font);
        }

        .InputBoxes {
            border-radius: 25px;
            height: 46px;
            margin-top: 2px;
        }

        .InputBoxesMessage {
            border-radius: 18px;
            max-height: 100px;
            margin-top: 2px;
        }


        .icon {
            position: relative;


            color: var(--border-signIn-Icon);
            font-size: 16px;
        }

        // Input {
        //     width: 100%;
        //     padding: 0.75rem 1rem 0.75rem 2.5rem;
        //     border: 1px solid $border-color;
        //     font-size: 0.9rem;
        //     outline: none;
        //     transition: border-color 0.2s;

        //     &:focus {
        //         border-color: $primary-color;
        //     }
        // }
    }

}