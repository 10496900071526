
.icon-container-ai-modals {
    -webkit-tap-highlight-color: transparent;
}

.AiNewAndClose {
    display: flex;
    align-items: center;
}

.card {
    max-height: 280px;
    min-height: 280px;
    height: 280px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;

    h4 {
        font-size: 16px;
    }

    p {
        font-size: 14px !important;
    }
}

.cards-container {
    overflow: hidden;

    .ant-modal-content {
        background-color: #fff !important;
        border-radius: 15px;
        border: none;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 10px !important;

    }
}

.ai-message {
    max-width: 60%;
}

.typing-indicator {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: #888;
}

.typing-indicator span {
    margin-left: 10px;
}

.ai-modal {
    .poweredby {
        display: flex;
        justify-content: center;
        color: var(--text-color-light);
        font-size: var(--font-12);
        font-weight: var(--weight-400);
        font-family: var(--primary-font);
        position: relative;

        .HAText {
            margin: 10px 0px 0px 0px;

        }

        .terms {
            color: var(--primary-color);
            font-size: var(--font-12);
            font-weight: var(--weight-400);
            font-family: var(--primary-font);
            cursor: pointer;

        }
    }

    .ant-modal-content {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 15px;
        border: none;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 0;
        transition: all 0.3s ease-in-out;
    }

    .aiHeader {
        .modal-header {
            background-color: #fff;
            padding: 0;
            border-radius: 15px 15px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            margin: 0;

            .header-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .logoAiChat {
                    padding: 0px 25px 0px 25px;
                    display: flex;
                    align-items: center;
                }

                .header-text {
                    margin-left: 10px;

                    h2 {
                        margin: 0;
                        padding: 0 15px;
                        font-size: 18px;
                        color: #00aaff;
                    }
                }

                .close-icon {
                    padding: 0px 25px 0px 25px;
                    font-size: 20px;
                    color: #999;
                    cursor: pointer;

                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }

    .modal-body {
        flex: 1;
        text-align: center;
        margin-bottom: 20px;
        padding: 0;
        overflow-y: auto;

        h3 {
            font-size: var(--font-30);
            color: var(--primary-color);
            font-weight: bold;
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
            transition: color 0.3s ease-in-out;
            margin: 88px 0px 0px 0px;
        }

        p {
            font-size: var(--font-16);
            font-family: var(--primary-font);
            font-weight: var(--weight-400);
            line-height: 34px;
            margin: 0 20px;
        }
    }

    .modal-footer {
        padding: 39px;
        border-radius: 0 0 15px 15px;
        margin-top: 0;
        padding: 0px 50px 20px 50px;
    }

    .modal-actions {
        text-align: center;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;

        .button-container {
            display: flex;
            gap: 10px;
            overflow-x: auto;
        }

        .button-container::-webkit-scrollbar {
            display: none;
        }

        button {
            margin: 0px 5px 0px 0px;
            background-color: var(--white);
            border: 1px solid var(--box-gray-modal);
            color: var(--gray-placeholder);
            border-radius: 30px;
            box-shadow: none;
            transition: all 0.3s ease;
            font-size: var(--font-16);
            font-weight: var(--weight-400);
            font-family: var(--primary-font);
            height: 41px;
            width: auto;
            min-width: auto;
            white-space: nowrap;


            &:hover {
                background-color: var(--primary-color) !important;
                color: #333;
            }
        }
    }

    .message-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;

        .message-input {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            padding: 10px;
            border: 1px solid #ccc;
            outline: none;
            font-size: 16px;
            transition: border-color 0.3s ease;

            &:focus {
                border-color: var(--primary-color);
            }
        }

        .send-button {
            margin: 0px 10px 0px 10px;
            height: 50px;
            min-width: 10px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--primary-color);
            border-radius: 50%;
            font-size: 20px;
            line-height: 1;
            box-shadow: none !important;
            border: none !important;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: var(--primary-color);
            }
        }

        .send-button:disabled {
            background-color: var(--gray);
            cursor: not-allowed;
        }

    }

    @media (max-width: 768px) {
        .modal-footer {
            padding: 0px 20px 40px 20px !important;
        }

    }
}

.conversation-container {
    padding: 20px 50px;
    max-height: 500px;
    height: 100%;
    overflow-y: auto;
}

.conversation-container::-webkit-scrollbar {
    display: none;
}

.message {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    animation: fadeIn 0.5s ease-out;
    position: relative;
}

.message.user {
    justify-content: flex-end;
}

.message.ai {
    justify-content: flex-start;
}

.message-text {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px;
    transition: all 0.3s ease;
    word-break: break-word;
    text-align: start;
    font-family: var(--primary-font);
    font-size: var(--font-16);
    font-weight: var(--weight-400);
    line-height: var(--font-18);
}

.message.user .message-text {
    background-color: #01BAFF;
    color: #fff;
    border-radius: 30px 0px 30px 30px;

    [dir='rtl'] & {
        border-radius: 0px 30px 30px 30px;
    }
}

.aiUserMsg {
    display: inline-block;
    flex-direction: column;
    max-width: 60%;
}

.message.ai .message-text {
    background-color: #fff;
    color: #333;
    border-radius: 0px 30px 30px 30px;
    border: 1px solid #CDCDCD;

    [dir='rtl'] & {
        border-radius: 30px 0px 30px 30px;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    .conversation-container {
        padding: 10px 20px 0px 20px !important;

    }

    .aiUserMsg {
        max-width: 80%;
    }

    .message-text {
        max-width: 100%;
    }

    .ai-message {
        max-width: 80%;
    }

    .logoAiChat {
        width: 145px;
    }

    .aiNewChatIcon {
        width: 25px;
    }

}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}