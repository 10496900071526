.home-container {
    margin: 3% 0 0px 0;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    gap: 60px;


    .props {
        padding: 0px 108px;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;


            h2 {
                margin: unset;
                font-size: var(--font-30);
                font-weight: var(--weight-600);
            }

            .rs-type {
                display: flex;
                gap: 14px;

                button {
                    display: block;
                    border-radius: 36px;
                    background: unset;
                    padding: 8px 39px;
                    border: 1px solid var(--box-gray);
                    color: var(--text-color-light);
                    font-size: var(--font-18);
                }

                .active {
                    border: unset;
                    background: var(--primary-color);
                    color: var(--white);
                    font-weight: var(--weight-500);
                }
            }
        }

        .see-more {
            color: var(--primary-color);
            font-size: var(--font-18);
            font-weight: var(--weight-600);
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            margin-top: 80px;
            text-decoration: underline;
            transition: color 0.3s ease;
            margin-bottom: 80px;
        }
    }

    @media (min-width: 1230px)  {
        .apartments{
            justify-content: space-between;
            gap: 20px;
            .item{
                flex: 0 1 calc(33.33% - 20px);
                max-width: calc(33.33% - 20px);
                .apartment-card{
                    width: 100%;
                    min-width: 100%;
                } 
            }
        }
        
    }

    @media (max-width:580px) {
        margin-top: 35px;
        gap: 30px;

        .props {
            padding: 20px;
                .see-more{
                    margin-top: 30px;
                }
            .title {
                flex-direction: column;
                align-items: start;
                gap: 30px;

                .rs-type {
                    justify-content: space-between;
                    width: 100%;

                    button {
                        width: 47%;
                    }
                }
            }
        }
    }
}