.agent-img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    z-index: 2;
    aspect-ratio: 3/3;
}
.more-filter-container-btn{
    border: 1px solid var(--box-gray);
    color: var(--box-gray);
    display: none;
    @media (max-width: 750px) {
        display: flex;
    }
}
.filters-container {
    border-bottom: 1px solid var(--box-gray);

    .filters-l {
        padding: 20px 6%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        font-family: var(--primary-font);
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        color: var(--text-color);
        .group-filter-container{
            .ant-select-selection-item-content{
                max-width: 160px;
               
                @media (min-width: 500px) and (max-width: 600px) {
                    max-width: calc(160px - 50px);
                }
                 @media (min-width: 600px) and (max-width: 768px) {
                    max-width: calc(160px - 20px);
                }
                @media (max-width: 500px){
                    max-width: 50px;

                }
            }
        }

        @media (min-width: 768px) and (max-width: 1250px) {
         flex-wrap: wrap;

        .ant-select {
            // flex: 0 0 40.33%; 
            flex-wrap: wrap;
        }
    }

        @media (min-width: 822px) and (max-width: 1250px) {
            padding: 16px 6%;
        }
        @media (max-width: 821px) {
            padding: 16px 3%;
        }
        @media (max-width: 1250px) {
            justify-content: normal;
            font-size: var(--font-14);
            column-gap: 4px;
            row-gap: 10px;
            flex-wrap: wrap;
            .text-icon {
                    display: none; 
                }
        }

        
    }
    
  .ant-input-affix-wrapper-focused,.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    box-shadow: none;
    border-color: var(--primary-color) !important;

  }

    .custom-select:not(:has(.ant-select-selection-placeholder)) {
        .ant-select-selector {
            border-color: var(--primary-color) !important;
            box-shadow: none;
        }

        .ant-select-arrow {
            color: var(--primary-color);

            path {
                stroke: var(--primary-color);
            }
        }
    }
    .beds-and-baths{
        .ant-select-arrow{
            @media (max-width:390px) {
                right: 0px !important;


        }
            }
        
    }

    .ant-select-single.custom-select:not(:has(.ant-select-selection-placeholder)) {
        .ant-select-selector {
            background: var(--filter-light-blue-background);
            box-shadow: none;
        }
    }

    .custom-autocomplete:not(:has(.ant-select-selection-placeholder)) {
        .ant-input-affix-wrapper {
            border-color: var(--primary-color) !important;
            background: var(--filter-light-blue-background);
            color: var(--primary-color);

            .ant-input-prefix,
            .ant-input-suffix {
                svg {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .hide-search {
        .ant-select-selection-search {
            display: none;
        }
    }

    .custom-select {
        width: 100%;
        max-width: 246px;
        height: 48px;

        .ant-select-selector {
            padding: 9px 25px;
            border-radius: 30px;
            border: 1px solid var(--box-gray);
            box-shadow: none;
            font-family: var(--primary-font);
            font-size: var(--font-16);
            font-weight: var(--weight-500);
            color: var(--text-color-light);
            height: 48px;
            .ant-select-selection-wrap{
                    align-self: center;
            }
            .ant-select-selection-search {
                margin-inline-start: 0px;
            }

        }

        .ant-select-selection-item {
            color: var(--primary-color);
            background: var(--filter-light-blue-background);
            border-radius: 30px;

            svg {
                fill: var(--primary-color);
            }
        }

        &:focus,
        &:hover,
        &-focused {
            border-color: var(--primary-color) !important;
            .ant-select-selector {
                border: 1px solid var(--primary-color) !important;
                box-shadow: none;

            }
        }

          @media (max-width: 1250px) and (min-width: 780px) {
            
            max-width: 230px;
            flex: 1 0 calc(17.33% - 10px);        
        }

        @media (max-width: 780px) and (min-width: 697px) {
            // max-width: 135px;
            flex: 1 0 calc(20.33% - 15px);
            box-sizing: border-box;
        }

        @media (max-width: 697px) and (min-width: 533px) {
            // max-width: 135px;
            flex:1 0 calc(25% - 10px);
            max-width: 100%;
        }
        @media (max-width: 532px) and (min-width: 348px) {
            flex: 1 0 calc(27.33% - 10px); 
            // width: 135px;
            // max-width: 135px;
        }

        @media (max-width: 348px) and (min-width: 261px) {
            flex: 1 0 calc(36.33% - 10px); 
            // width: 135px;
            // max-width: 135px;
        }

        @media (max-width: 262px) {
            flex: 1 0 calc(50% - 10px); 
            // width: 135px;
            // max-width: 135px;
        }
        @media (max-width: 480px) {
            // width: 135px;
            height: 35px;
            .ant-select-selector {
                padding: 8px 12px;
                height: 35px;
                font-size: var(--font-14);
            }
        }
        // @media (max-width: 382px) {
        //     width: 128px;
        // }
    }

    .ant-select-arrow {
        color: var(--text-color-light);
        padding-inline-end: 8px;

        // @media (max-width: 389px) {
        //     padding-inline-end: 0px;
        // }
        @media (max-width: 480px) {
            font-size: var(--font-14);
            padding-inline-end: 0px;
            // right: 5px;
        }
    }

    .custom-arrow-icon,
    .custom-advanced-icon {
        transition: transform 0.3s ease, fill 0.3s ease;
    }

    .ant-select-open .custom-arrow-icon,
    .dropdown-open .custom-arrow-icon {
        transform: rotate(180deg);

        path {
            stroke: var(--primary-color);
        }
    }

    .ant-select-focused .custom-advanced-icon {
        path {
            stroke: var(--primary-color);
        }
    }

    .ant-input-prefix,
    .ant-input-suffix {
        color: var(--text-color-light);
    }

    .ant-select-selection-placeholder {
        color: var(--text-color-light);
        // padding-inline-start: 20px;
        font-weight: var(--weight-500);
        inset-inline-start: 0px;
        inset-inline-end: 0px;

        @media (max-width: 480px) {
            padding-inline-start: 0px;
            font-size: var(--font-14);
        }
    }

    .ant-select-single {
        height: unset;

        .ant-select-selection-search {
            inset-inline-start: 0px;
            inset-inline-end: 0px;
        }

        .ant-select-selection-item {
            background: unset;
        }

        @media (max-width: 480px) {
            .ant-select-selector {
                padding: 9px 10px;
            }
        }
    }

    .rent {
        max-width: 133px !important;
        height: 48px;

        @media (max-width: 480px) {
            width: calc(29% - 3px);
            max-width: 82px !important;
            height: 35px;
            font-size: var(--font-14);

            .ant-select-selector {
                padding: 9px 10px;
            }
        }
    }

    .company{
        @media (max-width: 480px) {
            width: 140px;

        }
    }

    .btn {
        background: none;
        // padding: 14px 22px;
        border: 1px solid var(--box-gray);
        border-radius: 30px;
        cursor: pointer;
        font-family: var(--primary-font);
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        color: var(--text-color);
        height: 48px;

        .img-icon {
            margin-inline-end: 7px;
        }

        @media (max-width: 480px) {
            width: calc(33.33% - 3px);
            height: 35px;
            // padding: 14px 0px;
            font-size: var(--font-14);
          

        }

         @media (max-width: 1250px) {
            .img-icon {
                margin-inline-end: 0px;
            }
        }
    }

    .ant-input-affix-wrapper {
        width: 100%;
        height: 48px;
        padding: 9px 20px;
        border-radius: 30px;
        border: 1px solid var(--box-gray);
        font-family: var(--primary-font);
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        line-height: normal;
        color: var(--text-color);

        &:focus,
        &:hover,
        &-focused {
            border-color: var(--primary-color) !important;

            .ant-select-selector {
                border: 1px solid var(--primary-color) !important;
            }
        }

        input::placeholder {
            color: var(--text-color-light);
            text-overflow: clip;
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
        }

        @media (max-width: 480px) {
            // width: calc(38% - 3px);
            height: 35px;
            padding: 8px 10px;
            padding-inline-end: 0px;
            font-size: var(--font-14);
        }

        @media (max-width: 768px) {
            padding-inline-end: 5px;
        }
    }


    .search {
        background-color: var(--primary-color);
        color: var(--white);
        max-width: 133px;
        width: 100%;
        // height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1250px) {
            width: 37px;
            height: 36px;
        }
    }

    .more-filter{
        flex: none;
       
    }
    .more-filter-select{
        @media (max-width: 750px) {
            display: none;
        }
    }
     


    .save-btn {
        border-radius: 30px;
        border: 1px solid var(--primary-color);
        background: var(--white);
        color: var(--primary-color);

        max-width: 92px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        // @media (max-width: 480px) {
        //     width: calc(33.33% - 3px);
        // }

         @media (max-width: 1250px) {
            width: 48px;
            height: 48px;
        }

        @media (max-width: 480px) {
            width: 37px;
            height: 35px;
        }
    }
    

    .reset-container-btn{
        border: 1px solid var(--red);
        background: var(--white);
        color: var(--red);
        display: flex;

    }
    .reset-container-btn,.more-filter-container-btn {
        border-radius: 30px;
        max-width: 92px;
        width: 100%;
        justify-content: center;
        align-items: center;

        // @media (max-width: 1250px) {
        //     width: 37px;
        //     height: 36px;
        // }

           @media (max-width: 1250px) {
            width: 48px;
            height: 48px;
        }

        @media (max-width: 480px) {
            width: 37px;
            height: 35px;
        }

        
    }
    .search-btn{
        @media (max-width: 1250px) {
            width: 48px;
            height: 48px;
        }

        @media (max-width: 480px) {
            width: 37px;
            height: 35px;
        }
    }

    .active {
        background: var(--filter-blue-background);
        border-color: var(--box-blue);
    }

    .custom-autocomplete {
        width: 100%;
        // max-width: 317px;
        height: 48px;
        font-family: var(--primary-font);
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        line-height: normal;
        color: var(--text-color);

        @media (max-width: 1250px) and (min-width: 780px) {
            &.agents-select{
                // max-width: 43%;
                max-width: 212px;
            }
        }
        @media (max-width: 780px) and (min-width: 769px) {
             &.agents-select{
                max-width: 160px;
            }
        }

        @media (max-width: 768px) {
            font-size: var(--font-14);
            // width: 150%;
            &.agents-select{
                // max-width: 43%;
                max-width: 143px;
            }
            .ant-select-selector {
                padding: 0px;
                height: 35px;
                font-size: var(--font-14);
            }
        }

    
        @media (max-width: 480px) and (min-width: 348px) {
            // width: calc(38% - 3px);
            height: 35px;
            &.agents-select{
                max-width: 134px;
            }
            .ant-select-selector {
                padding: 0px;
                height: 35px;
                font-size: var(--font-14);
            }
        }
        @media (max-width: 348px) {
            height: 35px;

            &.agents-select{
                max-width: -webkit-fill-available;
                flex: 1 0 calc(42% - 10px);
            }
        }
    }

   

}