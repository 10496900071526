.property-info-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px;
    border-top: 1px solid var(--box-gray);
    border-bottom: 1px solid var(--box-gray);
    direction: initial;
    flex-wrap: wrap;

    .property-info-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1 1 25%;
        gap: 14px;
        padding: 0 20px;
        position: relative;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            width: 1px;
            background-color: var(--box-gray);
        }
    }

    .icon-container {
        background-color: rgba(1, 186, 255, 0.10);
        padding: 12px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    .icon-container img {
        width: 25px;
        height: 25px;
    }

    .property-info-item span {
        color: var(--text-color);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
    }
}

@media (max-width: 820px) {
    .property-info-container {
        padding-right: 0;
        padding-left: 0;
        flex-wrap: wrap;

        .property-info-item {
            padding: 0;
        }

        .icon-container {
            padding: 9px;
            width: 36px;
            height: 36px;
        }

        .icon-container img {
            width: 18px;
            height: 18px;
        }
    }
}

@media (max-width: 570px) {
    .property-info-container {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;

        .property-info-item {
            flex: 1 1 50%;
            padding: 10px 0;
            text-align: center;
            position: relative;


            &:not(:last-child)::after {
                content: '';
                position: absolute;
                right: 0;
                height: 100%;
                width: 0px;
                background-color: var(--box-gray);
            }


            &:nth-child(1)::after,
            &:nth-child(1)::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--box-gray);
            }

            &:nth-child(3)::before,
            &:nth-child(3)::before {
                content: '';
                position: absolute;
                bottom: 0;
                right: -1px;
                width: 1px;
                height: 100%;
                background-color: var(--box-gray);
            }

            &:nth-child(2)::after,
            &:nth-child(2)::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--box-gray);
            }

            &:nth-child(2)::before,
            &:nth-child(2)::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 1px;
                height: 100%;
                background-color: var(--box-gray);
            }


        }

        .property-info-item span {
            font-size: var(--font-14);
        }

        .icon-container {
            margin-bottom: 8px;
        }
    }
}