.icon-container-ai {
    position: fixed;
    bottom: 60px;
    margin-inline-start: 61px;
    z-index: 11;
    cursor: pointer;
    max-width: 80px;

    -webkit-tap-highlight-color: transparent;

    .ai-icon {
        width: 76px;
        height: auto;
        border-radius: 38.182px;
        cursor: 'pointer'
    }
}

@media (max-width: 600px) {
    .icon-container-ai {
        bottom: 20px;
        margin-inline-start: 20px;
    }
}