.contact-us-section {
    padding: 42px 108px 120px 108px;
    background: transparent;
    border-radius: 10px;
    margin: 20px 0;
   max-width: 100%;
    .text-container-header {
        h5 {
            color: var(--black);
            font-size: var(--font-24);
            font-weight: var(--weight-500);
            margin: 0px;
        }
    }

    .text-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0px;

        h2 {
            color: var(--black);
            font-size: var(--font-30);
            font-weight: var(--weight-700);
            font-family: var(--primary-font);
            // line-height: 100px;
        }


        .request-button {
            width: 260px;
            height: 54px;
            padding: 14px 20px;
            justify-content: center;
            align-items: center;
            gap: 18px;
            border-radius: 37px;
            background-color: var(--primary-color);

            span {
                color: var(--white);
                text-align: center;
                font-size: var(--font-18);
                font-weight: var(--weight-600);
            }

            .arrow {
                font-size: var(--font-18);
                margin-inline-start: 5px;
            }
        }
    }


    @media (max-width:580px) {
        padding: 20px 20px 120px 15px;
        margin: 0px 0 !important;

        .text-container {
            flex-direction: column;
            margin-top: 0px;
            gap: 10px;
            margin: 0 !important;
            align-items: start;

            h2 {
                display: flex;
                justify-content: start !important;

            }

            .request-button {
                width: 173px;
                height: 36px;
                margin-left: auto;
                /* Centers the button */
                margin-right: auto;
                /* Centers the button */

            }
        }

    }

}

.contact-us-form-modal {

    width: 1210px !important;
    height: 589px !important;

    .ant-modal-content {
        border-radius: 30px;
        background: var(--white);
        padding: 60px;
    }

    h2 {
        color: var(--text-color);
        font-size: var(--font-30);
        font-weight: var(--weight-700);
        margin-top: 20px;
        margin-bottom: 75px;
    }

    .ant-form-item-label>label {
        font-size: 14px;
        font-weight: 500;
        color: #333;
    }

    .submit-btn {
        width: 260px;
        height: 54px;
        padding: 14px 20px;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 37px;
        background-color: var(--primary-color);

        span {
            color: var(--white);
            text-align: center;
            font-size: var(--font-18);
            font-weight: var(--weight-600);
        }

        .arrow {
            font-size: var(--font-18);
            margin-inline-start: 5px;
        }
    }

    @media (max-width:580px) {

        .ant-modal-content {
            padding: 20px;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 20px;
        }

        .submit-btn {
            margin-top: 30px;
        }

    }
}