.contact-us-form {
    width: 100% !important;
    height: auto !important;
    overflow-x: hidden;

    .getInTouch{
        color:var(--text-color);
        font-size: var(--font-30);
        font-weight: var(--weight-700);
        font-family: var(--primary-font);
        margin: 0;
        padding-bottom: var(--font-35);
    }

    .ant-modal-content {
        border-radius: 20px;
        padding: 16px;

        @media (min-width: 768px) {
            padding: 60px 60px 40px 60px;
        }
    }

    .ant-modal-title {
        color: var(--black);
        font-size: var(--font-30);
        font-weight: var(--weight-700);
        line-height: 100px;
        padding: 0px 60px 0px 0px;
        margin-bottom: 4px;

    }

    .field-label {
        color: var(--text-color);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        line-height: 36px;
        font-family: var(--primary-font);
    }

    .ant-form-item {
        margin-bottom: 22px;
        height: auto;

        .ant-select {
            height: 46px;
        }

        .ant-select-selector,
        .ant-input-affix-wrapper {
            border-radius: 25px;
            padding: 12px;
            font-size: var(--font-14);
            border: 1px solid var(--box-gray-modal) !important;
            box-shadow: 0px 1px 2px 0px rgba(242, 242, 242, 0.24) !important;
            height: 46px;
            align-self: stretch;
        }

        .ant-input::placeholder {
            color: var(--gray-placeholder);
            display: flex;
            align-items: center;
        }

        .ant-input-prefix {
            margin-inline-end: 0px;

        }
    }

    .icon-text-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span,
        .select-icon {
            margin-inline-end: 14px;
            color: var(--gray-placeholder);
            font-size: var(--font-14);
            font-weight: var(--weight-400);
        }

    }

    .submit-btn-container {
        display: flex;
        justify-content: start;
        align-items: end !important;
        margin-top: 33px;
    }


    .submit-btn {
        width: 260px;
        height: 49px;
        padding: 14px 20px;
        justify-content: center;
        align-items: center;
        gap: 18px;
        border-radius: 37px;
        background-color: var(--primary-color);
        border: none;
        box-shadow: none !important;

        span {
            color: var(--white);
            text-align: center;
            font-size: var(--font-18);
            font-weight: var(--weight-600);
        }

        .arrow {
            font-size: var(--font-18);
            margin-inline-start: 5px;
        }
    }

    .online-registration-container {
        text-align: center;
        margin-top: 20px;
    }

    .line-with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }

    .horizontal-line {
        flex: 1;
        height: 1px;
        background-color: var(--box-gray-modal);
        margin: 0 10px;
        max-width: 338px;
    }

    .center-icon {
        width: 16px;
        height: 16px;
    }

    .orText {
        color: #6C7278;
        font-size: var(--font-12);
        font-family: var(--primary-font);
        font-weight: var(--weight-400);

    }


    @media (max-width: 768px) {
        .ant-modal-title {
            line-height: 24px;
        }

        .ant-modal-content {
            width: 100%;
            height: 100%;
            padding: 45px 20px 40px 20px;
        }

        .submit-btn-container {
            justify-content: center !important;
            align-items: center;
        }

        .ant-form-item {

            input,
            textarea,
            .ant-select-selector {
                font-size: var(--font-14);
            }
        }

        .submit-btn {
            font-size: var(--font-14);
            width: 192px;
            height: 48px;
            margin-top: 0px;
        }

        .submit-btn-container {
            margin-top: 0px;

        }
    }
}