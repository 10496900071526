@import "~react-image-gallery/styles/scss/image-gallery.scss";

.p-container {

    .prop-gallery {
        display: none;
        position: relative;

        .image-gallery-image {
            width: 100%;
            height: 331px;
            object-fit: cover;
        }

        // .image-gallery-bullet {
        //     background-color: var(--active-filter-btn-bg);
        //     padding: 2px;
        //     box-shadow: unset;
        // }

        .image-gallery-bullet:hover {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }

        .image-gallery-bullet.active {
            transform: scale(1.2);
            border: 1px solid var(--primary-color);
            background: var(--primary-color);
        }

        .image-gallery-slide-wrapper .image-gallery-left-nav,
        .image-gallery-slide-wrapper .image-gallery-right-nav {
            opacity: 0;
        }

        .image-gallery-slide-wrapper:hover .image-gallery-left-nav,
        .image-gallery-slide-wrapper:hover .image-gallery-right-nav {
            opacity: 1;
        }

        .image-gallery-svg {
            width: 15px;
        }

        .image-gallery-icon {
            filter: unset;
        }

        .action-btns {
            position: absolute;
            top: 16px;
            inset-inline-end: 16px;
            z-index: 4;
            display: flex;
            gap: 7px;

            .lf-btn {
                cursor: pointer;
            }
        }

        .image-gallery-index {
            left: 20px;
            right: unset;
            top: unset;
            bottom: 20px;
            align-items: center;
            // width: 40px;
            height: 19px;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.55);
            padding: 0px 8px;

            span {
                color: var(--white);
                font-size: var(--font-10);
                font-weight: var(--weight-500);
            }

            @media (max-width: 768px) {
                span {
                    font-size: var(--font-12);
                }

                background: unset;
            }
        }
    }

    .property-container {
        margin: 4% 6% 130px 6%;

        .border {
            width: 10%;
            height: 3px;
            background-color: var(--primary-color);
        }

        .info {
            display: flex;
            justify-content: flex-start;
            gap: 21px;
            margin-top: 40px;

            .main {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 70%;
                min-width: 70%;
                gap: 40px;

                .main-description {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 16px;

                    .primary {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;


                        .listed-from {
                            color: var(--text-color-light);
                            margin-bottom: 16px;
                            font-size: var(--font-18);
                            display: block;
                            font-weight: var(--weight-400);
                        }

                        .price {
                            font-weight: var(--weight-600);
                            font-size: var(--font-30);
                            line-height: 35px;
                            color: var(--black);
                        }

                        .share-btns {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            .fv-btn {
                                padding: unset;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 6px 18px;
                                background-color: var(--primary-color);
                                color: #fff;
                                height: max-content;
                                border: unset;
                                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                                border-radius: 4px;
                                gap: 4px;
                                font-size: var(--font-18);
                                line-height: 23px;
                            }
                        }

                    }

                    .secondary {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: 16px;
                        font-weight: var(--weight-400);


                        .location {
                            display: flex;
                            gap: 8px;
                            font-size: var(--font-25);
                            color: var(--text-color);
                        }

                        .location-features {
                            font-size: var(--font-18);
                            line-height: 29.3px;
                            color: var(--text-color-light);
                        }

                    }
                }

                .prop-information {
                    h1 {
                        font-size: var(--font-30);
                        font-weight: var(--weight-600);
                        line-height: 35.16px;
                        margin-bottom: 4px;
                    }
                }

                h2 {
                    margin: unset;
                    font-size: var(--font-30);
                    font-weight: var(--weight-600);
                    line-height: 35.16px;
                    margin-bottom: 4px;
                }

                .info-grids {
                    display: flex;
                    flex-direction: row;
                    gap: 60px;
                    margin-top: 10px;

                    .info-grid {
                        border-collapse: collapse;

                        th {
                            padding: 16px 20px 16px 0;
                            text-align: start;
                            font-size: var(--font-16);
                            line-height: 18.75px;
                            font-weight: var(--weight-400);
                            color: var(--text-color-light);
                        }

                        tr:not(:last-child) th {
                            border-bottom: 1px solid #ccc;
                        }

                        .bold {
                            font-weight: var(--weight-600);
                            color: var(--text-color);
                        }

                    }
                }

                .features {
                    .features-grid {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 10px;
                        margin-top: 20px;

                        .item {
                            display: flex;
                            gap: 4px;
                            align-items: flex-end;
                            font-size: var(--font-16);
                            line-height: 18.75px;
                            font-weight: var(--weight-400);
                        }

                        .checkbox-round:checked {
                            background-color: var(--primary-color);
                            color: var(--white);
                            border-radius: 50%;
                        }

                        // input[type='checkbox'] {
                        //     accent-color: var(--primary-color);
                        // }
                    }

                }

                .rera-info {
                    display: flex;
                    align-items: center;
                    gap: 100px;

                    .qr {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 29px;
                        font-weight: var(--weight-600);
                        color: var(--text-color);

                        img {
                            max-width: 130px;
                        }

                    }
                }

                .more-in-this-area {
                    margin-top: 80px;

                    .more-title {
                        color: var(--black);
                        font-size: var(--font-25);
                        font-weight: var(--weight-600);

                        margin-bottom: 40px;
                    }
                }

            }

            .recomended {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 35px;
                position: sticky;
                top: 25px;
                align-self: flex-start;

                .company {
                    // width: 352px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    margin-bottom: 25px;

                    .company-logo-container {
                        width: 100px;
                        height: 80px;
                        display: flex;
                        align-content: center;
                        justify-content: center;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                        }
                    }

                    .company-info {
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        font-size: var(--font-20);
                        font-weight: var(--weight-500);
                        color: var(--text-color);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .view {
                            font-size: var(--font-12);
                            font-weight: var(--weight-400);
                            text-decoration-line: underline;
                        }
                    }
                }
            }
        }
    }

    @media (max-width:820px) {
        .prop-gallery {
            display: block;
        }

        .property-container {

            .img-container,
            .action-btns {
                display: none;
            }

            .info {
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;

                .main {
                    width: 100%;
                    gap: 25px;

                    .more-in-this-area h2 {
                        margin-bottom: 10px;
                    }

                    .main-description {
                        gap: 8px;

                        .primary .listed-from {
                            margin-bottom: 8px;
                        }

                        .secondary {
                            gap: 8px;

                            .location {
                                img {
                                    display: none;
                                }
                            }
                        }
                    }

                    .main-description .primary .fv-btn {
                        line-height: 18.75px;
                        gap: 8px;

                        img {
                            max-width: 20px;
                        }
                    }


                    .info-grids {
                        flex-direction: column;
                        gap: unset;
                        width: -webkit-fill-available;

                        .info-grid {
                            th {
                                border-bottom: 1px solid #ccc;
                                width: 50%;
                            }
                        }
                    }

                    .features {
                        .features-grid {
                            grid-template-columns: repeat(2, 1fr);
                        }

                    }

                    .rera-info {
                        flex-direction: column;
                        gap: 30px;

                    }

                }


                .recomended {
                    width: 100%;
                }


            }
        }
    }
}