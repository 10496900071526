.banner {
    height: 95vh;
    // min-height: 1024px;
    max-height: 900px;
    width: 100%;
    background-image: url('https://housez.s3.ap-south-1.amazonaws.com/Website/Background/banner3.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    // border-radius: 14px;
    position: relative;
    @media (max-width: 600px) { 
        height: 324px;
    }
    .wrapper {
        color: var(--black);
        font-family: var(--primary-font);
        font-size: var(--font-40);
        font-weight: var(--weight-600);
        line-height: 61px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3% 6%;
        font-style: normal;

        .title {
            font-size: var(--font-48);
        }
        .group-filter-container{
            &.advanced-filter-container{
                .dropdown-overlay{
                    top:64px;
                }
            }
            .min-max-input{
                margin-top: 0px;
            }
            .more-filter-container-btn{
                display: none;
            }
            .dropdown-content{
                width: 400px;
                gap: 10px;
                .filter-section {
                    h3 {
                        margin-bottom:0px ;
                    }
                }
                .filter-buttons{
                    justify-content: center;
                    .apply-btn{
                        display: none;
                    }
                    .reset-btn{
                        border:0px
                    }
                }
                @media (max-width: 860px) and (min-width: 720px) {
                    width: 300px;

                }  
            }
        }
        // .subtitle {
        //     font-weight: var(--weight-500);
        // }

    }

    .banner-filters {
        width: 100%;
        position: absolute;
        left: 25%;
        top: 70%;

    }


    @media (max-width:480px) {
        .wrapper {
            .title {
                font-size: var(--font-35);

            }
        }
    }
    @media (max-width:359px) {
        .wrapper {
                line-height: 36px;
        }
    }
}