div .ant-pagination {
    margin-top: 60px;
    background: var(--white);
    justify-content: center;

    .ant-pagination-item {
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        border-radius: 25px;
        border-color: var(--primary-color);
        background: var(--white);
        transition: background-color 0.3s, color 0.3s;

        a {
            color: var(--primary-color);
            font-family: var(--primary-font);
            font-size: var(--font-18);
            font-weight: 400;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &:hover {
            background: var(--primary-color);

            a {
                color: var(--white);
            }
        }

        &-active {
            background: var(--primary-color);

            a {
                color: var(--white);
            }
        }
    }

    .ant-pagination-item-link {
        flex-shrink: 0;
        color: var(--primary-color);
        transition: color 0.3s;

        &:hover {
            color: var(--white);
            background: var(--primary-color);
        }
    }

    .ant-pagination-jump-next {
        margin-inline-end: 0;
    }

    .ant-pagination-item:not(.ant-pagination-item-active):hover {
        background-color: var(--primary-color);
    }
}

.custom-pagination {
    &.rtl-pagination {

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            transform: rotate(180deg);
        }
    }
}