.delete-account-modal {
    max-width: 381px;
  
    .input-group {
        position: relative;
        margin-bottom: var(--font-20);
        text-align: start;

        span {
            color: var(--text-color);
            font-weight: var(--weight-400);
            font-size: var(--font-14);
            font-family: var(--primary-font);
        }

        .InputBoxes {
            border-radius: 25px;
            height: 46px;
            margin-top: 2px;
        }


        .icon {
            position: relative;


            color: var(--border-signIn-Icon);
            font-size: 16px;
        }
     
    }

    h2 {
        font-size: var(--font-20);
        font-weight: var(--weight-500);
        margin-bottom: var(--font-16);
        color: var(--edit-profile-text);
        font-family: var(--primary-font);
    }

    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner::before {
        color: #fff !important;

    }

    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner::after {
        content: url('../../../../public/images/apts/icons/radioSign.svg');

        color: #fff !important;
        scale: 3;
        position: absolute;
        top: 5px;
        left: 12.5px;
        background-color: transparent;
        transition: all 0.2s;

    }

    .ant-radio-wrapper .ant-radio-input:checked+.ant-radio-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        box-shadow: none;

    }

    .ant-radio-wrapper .ant-radio-inner {
        width: 20px;
        height: 20px;
    }

    .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: var(--font-18);
        padding-bottom: var(--font-18);

        .ant-radio-wrapper {
            font-size: var(--font-16);
            color: var(--black);
            font-family: var(--primary-font);
            font-weight: var(--weight-400);
            border-color: var(--primary-color);

            .ant-radio-inner {
                border-color: var(--radio-border-color);

                &:hover {
                    color: var(--black);
                    border-color: var(--primary-color);
                }
            }


        }

        .ant-radio-checked .ant-radio-inner {
            border-color: var(--primary-color);

        }
    }

    .submit-div {
        padding: 0px 15px 0px 15px;

        .submit-button {
            width: 100%;
            padding: 0.75rem;
            background-color: var(--primary-color);
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            border: none;
            border-radius: 25px;
            cursor: pointer;
            transition: background-color 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            height: 46px;

        }
    }

}