.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
    -webkit-tap-highlight-color: transparent;


    &.prev {
        left: -10px;
    }

    &.next {
        right: -30px;
    }

    [dir='rtl'] & {
        &.prev {
            left: -25px;
        }

        &.next {
            right: -5px;
        }
    }

    &:hover {
        color: #f0f0f0;
    }

    @media (max-width: 768px) {
        &.prev {
            left: -5px;
            scale: 0.8;
        }

        &.next {
            right: -25px;
            scale: 0.8;
        }
    }
  
}