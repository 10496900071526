.popular-search-container {
    width: 100%;
    padding: 70px 108px;
    background-color: var(--off-white);
    display: flex;
    flex-direction: column;
    align-items: center;

    .search-title {
        color: var(--black);
        font-size: var(--font-35);
        font-weight: var(--weight-600);
        line-height: 52px;
        margin-top: 0px;
        margin-bottom: 50px;
    }

    .search-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 21px;
        column-gap: 25px;
        padding: 0 70px;

        .search-tag {
            padding: 1px 26px;
            color: var(--text-color);
            background-color: var(--white);
            font-size: var(--font-14);
            font-weight: var(--weight-500);
            line-height: 48px;
            border-radius: 50px;
            border: 1px solid var(--text-color);
            cursor: pointer;
            transition: all 0.3s ease;
            text-align: center;

            &:hover {
                border-color: var(--primary-color);
                color: var(--primary-color);
            }
        }
    }


    @media (max-width:580px) {
        padding: 20px;

        .search-title {
            margin-bottom: 10px;
            text-align: left;
        }

        .search-wrapper {
        padding: 0 20px;
        .search-tag {
                width: 100%;
            }
        }
    }

}