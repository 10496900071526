.tabs-container {
    margin-top: 56px;
    width: 100%;
    
    .tabs-header {
        display: flex;
        border-bottom: 1px solid var(--box-gray);
        justify-content: flex-start;
        font-family: var(--primary-font);
        font-size: var(--font-18);
        font-style: normal;
        font-weight: var(--weight-400);
        line-height: normal;

        button {
            background: none;
            border: none;
            color: var(--black);
            font-size: var(--font-18);
            font-weight: var(--weight-400);
            position: relative;
            padding-top: 0;
            padding-bottom: 19px;
            padding-inline-start: 0px;
            cursor: pointer;
            outline: none;

            margin-inline-end: 18%;
            width: 144px;
            text-align: start;

            .label{
                margin-bottom: 4px;
            }

            span {
                color: var(--text-color-light);
                font-size: var(--font-16);
            }

            &:hover {
                color: var(--primary-color);
            }

            &.active {
                color: var(--primary-color);
            }
        }

        .underline {
            width: 100%;
            height: 2px;
            background: var(--primary-color);
            position: absolute;
            bottom: 0px;
            left: 0;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }

        .active .underline {
            transform: scaleX(1);
        }
    }

    .tabs-content {
        padding-top: 41px;
    }
}