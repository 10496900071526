:root {
    --primary-color: #01BAFF;
    --primary-font: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
    --secondery-color: #3870B8;
    --sitting-header-bg: #F8F8F8;
    --active-filter-btn-bg: #F2FCFF;
    --footer-bg: #FBFBFB;
    --black: #000;
    --white: #fff;
    --sign-in-gray: #6C7278;
    --border-signIn-Icon: #ddd;
    --sign-in-text: #1A1C1E;
    --edit-profile-text: #555555;
    --red: #FF3A44;
    --off-white: #F9F9F9;
    --gray: #828282;
    --gray-placeholder: #A9A9A9;
    --blogs-breadCrumbs-color: #555555;
    --text-color: #333333;
    --text-color-light: #808080;
    --filter-blue-background: #8ec5fc1a;
    --filter-light-blue-background: rgba(1, 186, 255, 0.05);
    --devidor: #1E283312;
    --box-blue: #8EC5FC;
    --box-gray: #F2F2F2;
    --box-gray-btn: #E7E7E7;
    --box-gray-modal: #CDCDCD;
    --radio-border-color: #ADADAD;
    --font-10: 10px;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --font-16: 16px;
    --font-18: 18px;
    --font-20: 20px;
    --font-24: 24px;
    --font-25: 25px;
    --font-30: 30px;
    --font-35: 35px;
    --font-40: 40px;
    --font-45: 45px;
    --font-48: 48px;
    --font-50: 50px;
    --font-55: 55px;
    --font-65: 65px;
    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
    --max-width: 1550px;
}

@media (max-width: 880px) {
    :root {
        --font-10: 10px;
        --font-12: 12px;
        --font-13: 13px;
        --font-14: 14px;
        --font-16: 14px;
        --font-18: 18px;
        --font-20: 18px;
        --font-24: 16px;
        --font-25: 18px;
        --font-30: 18px;
        --font-35: 22px;
        --font-40: 26px;
        --font-45: 30px;
        --font-48: 27px;
        --font-50: 20px;
        --font-55: 30px;
        --font-65: 33px;
    }

}