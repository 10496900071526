.custom-layout-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
    -webkit-tap-highlight-color: transparent;


    &.prev {
        left: -12px;
    }

    &.next {
        right: -30px;
    }

    [dir='rtl'] & {
        &.prev {
            left: 5px;
            padding: 0;

        }

        &.next {
            right: 5px;
            padding: 0;

        }
    }

    &:hover {
        color: #f0f0f0;
    }

    @media (max-width: 768px) {
        &.prev {
            left: 5px;
            scale: 1;
            transform: translateY(-50%);
            padding: 0;
        }

        &.next {
            right: 5px;
            scale: 1;
            transform: translateY(-50%);
            padding: 0;
        }
    }
}