.popular-card {
    max-width: 420px;
    width: 100%;
    height: 287px;
    margin-right: 0 !important;

    .bc-img {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        background-size: cover;
        background-repeat: no-repeat;

    }

    .wrapper {
        margin-right: 0 !important;

        color: var(--white);
        width: -webkit-fill-available;
        height: 100%;
        border-radius: 14px;
        padding: 22px;
        gap: 14px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: start;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.44) 100%);

        .title {
            color: var(--white);
            display: flex;
            flex-direction: column;
            font-size: var(--font-25);
            font-weight: var(--weight-600);

            .area {
                font-size: var(--font-30);
                line-height: 35.16px;
            }
        }

        .view {
            display: flex;
            gap: 14px;
            align-items: center;
        }
    }
}