.min-max-input {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
    gap: 9px;

    &__label {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 12px;

        color: var(--text-color-light);
        font-size: var(--font-16);
        font-weight: var(--weight-400);

    }

    &__field {
        background-color: #FBFBFB;
        border-radius: 14px;
        border:0px;
        // border: 1px solid #ddd;
        padding: 10px;
        font-size: 16px;
        width: 100%;
        &:focus{
            outline: 0px;

        }
    }

    input::placeholder {
        color: #aaa;
    }
}