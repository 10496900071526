.icon-only-select {
    width: 150px;
    height: 48px;
    padding: 0;

    .ant-select-selector {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input,
    .ant-select-show-search,
    .ant-select-selection-item {
        display: none;
    }

}

.advanced-filter-container {
    width: max-content !important;
     
    [dir="rtl"] & {
        @media (max-width: 500px) {
            .dropdown-overlay {
            transform: translateX(-15%);}
        }
    }

    @media (max-width: 500px) {
        .dropdown-overlay {
            width: fit-content;
            transform: translateX(16%);
        }
    }
    @media (max-width: 750px) {
        max-width: max-content !important;
        display: flex;
        justify-content: center;
    }

    .dropdown-overlay {
        left: unset;
        // right: 0;
    }

    [dir='rtl'] & {
        .dropdown-overlay {
            right: unset;
            left: 0;
        }
    }
    .filter-buttons{
        background-color: #fff;
    }
}

.custom-home-page-filter {
    width: 100% !important;
    .filter-buttons{
        background-color: transparent;
    }
}