.filter-count-display {
    color: var(--black);
    width: 100%;
    // text-align: left;
    font-family: var(--primary-font);
    font-size: var(--font-25);
    font-style: normal;
    font-weight: var(--weight-400);
    line-height: normal;
    margin: 0 1.2% 31px 1.2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-count-display-text{
        width: 100%;
    }
    .sort{
        justify-content: space-between;
        width: 100%;

        @media (min-width: 1024px) and (max-width: 1290px) {  
            max-width:400px
        }
        @media (min-width: 1290px) {  
            max-width: calc(95% - 1px);
            width: calc(95% - 1px);
        }

        @media (min-width: 1447px) and (max-width: 1540px) {  
            // max-width: calc(96% - 10px);
            // width: calc(96% - 10px);
            width:840px;
            max-width:840px
        }

        @media (min-width: 1540px) {  
            max-width: calc(95% - 55px);
            width: calc(95% - 55px);
        }
        .sort-btn{
            width: auto;
            height: 35px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 7px 22px 7px 28px;
            border: 1px solid var(--box-gray-btn);
            background-color: transparent;
            border-radius: 37px;
            color: var(--text-color-light);
            font-size: var(--font-18);
            outline: 0px;
            .img-icon{
                height: 12px;
                margin: 0px 5px;
            }

             
            // @media (max-width: 400px) {
            //     transform: translateX(-8%);

            // }

        }
    }
    .count {
        color: var(--text-color-light);
        font-family: var(--primary-font);
        font-size: var(--font-20);
        font-style: normal;
        font-weight: var(--weight-400);
        line-height: normal;
        margin-top: 8px;
    }
    .btns{
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: 768px) {
        font-size: var(--font-20);

        .count {
            font-size: var(--font-18);
        }
    }

    @media (max-width: 480px) {
        font-size: var(--font-18);

        .count {
            font-size: var(--font-14);
        }
    }
}