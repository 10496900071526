.dropdown-container {
    position: relative;
    font-family: 'Arial', sans-serif;


    .dropdown-toggle {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        user-select: none;
        padding: 8px 12px;
        color: var(--text-color);

        img {
            width: 20px;
            height: 20px;
        }

        span {
            font-size: 14px;
            font-weight: 500;
        
        }
       

        .arrow {
            font-size: 12px;
            transition: transform 0.3s ease;

            &.up {
                transform: rotate(180deg);
            }
        }

    }

    .dropdown-menu {
        position: absolute;
        top: 110%;
        left: -60px;
        height: auto;
        background-color: var(--white);
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 21px 25px 21px 25px;
        width: 219px;
        z-index: 1000;

        a,
        .user-dropdown-item {
            padding: 12px 0;
            cursor: pointer;
            transition: background-color 0.2s ease, color 0.2s ease;
            display: flex;
            align-items: center;
            gap: 12px;
            text-decoration: none;
            color: var(--text-color);
            font-size: var(--font-16);
            font-weight: var(--weight-400);
            font-family: var(--primary-font);
            border-bottom: 2px solid var(--box-gray);

            img {
                width: 16px;
                height: 16px;
            }

            &:hover {
                color: var(--primary-color);
            }

            &:not(:last-child) {
                border-bottom: 2px solid var(--box-gray);
            }


            &:last-child {
                border-bottom: none;
            }

            &:first-child {
                padding-top: 0;
            }
        }

    }

    .user-option-container {
        display: inline-block;
        padding: 5px;
        border-radius: 50%;
        background-color: var(--light-gray);
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
        }
    }
}

.user-dropdown {
    span {
        color: var(--primary-color);
    }

    .danger {
        color: var(--red) !important;
    }
}