.popular {
    h2 {
        color: var(--black);
        margin: 0;
        font-size: var(--font-35);
        font-weight: var(--weight-600);
        margin-bottom: 40px;
        padding: 0px 70px 0px 0px;
    }

    .popular-residential {
        
        width: 100%;
        .slider {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .navigates {
                display: flex;
                flex-direction: column;
                gap: 22px;
                align-items: center;
                justify-content: center;
                width: 15%;

                .navigate {
                    cursor: pointer;
                    user-select: none;

                    :hover {
                        fill: var(--primary-color);
                    }
                }

                .navigate.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            .swiper {
                width: 84%;
                margin: unset;
            }

            .swiper-slide {
                max-width: 420px;
             
            }

        }
        .prev-btn,
        .next-btn {
            -webkit-tap-highlight-color: transparent;
        }
    }
}

/* RTL direction */
:dir(rtl) .popular {
    padding: 70px 108px 70px 0px !important;
}
:dir(ltr) .popular {
    padding: 70px 0px 70px 108px ;
}

@media (max-width: 580px) {
    .popular {
        h2{
            padding: 0;
        }
        .swiper-slide{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .popular-residential {
            .slider {
                .navigates {
                    display: none;
                }

                .swiper {
                    width: 100%;
                }
            }
        }
    }

    :dir(rtl) .popular {
        padding: 20px !important;
    }
    :dir(ltr) .popular {
        padding: 20px !important;
    }
}
