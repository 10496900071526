.company-profile {
    margin-bottom: 130px;

    .company-header {
        position: relative;
        background: rgba(1, 186, 255, 0.03);

        .header-background {
            margin-bottom: -4px;
            width: 100%;
            height: 234px;
            object-fit: cover;
        }

        .company-details {
            margin: 0 6%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: auto;
            position: relative;

            .personal {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                position: absolute;
                transform: translateY(-35%);
                z-index: 1;

                .company-logo-container {
                    width: 210px;
                    height: 210px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    background-color: var(--white);
                    padding: 7%;
                }

                .company-logo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }

                .company-logo-placeholder {
                    width: 100%;
                    height: 100%;
                    background-color: var(--white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 50px;
                    color: var(--black);
                    font-weight: bold;
                    border-radius: 50%;
                }

                h1 {
                    margin-top: 26px;
                    color: var(--black);
                    text-align: center;
                    font-family: var(--primary-font);
                    font-size: var(--font-25);
                    font-style: normal;
                    font-weight: var(--weight-600);
                    line-height: 1.2;
                }

                p {
                    color: var(--text-color-light);
                    font-family: var(--primary-font);
                    font-size: var(--font-14);
                    font-weight: var(--weight-400);
                    line-height: 1.5;
                    margin: 5px;
                }
            }

            .personal-hide {
                width: 34%;
            }

            .contact {
                width: 80%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;
                text-wrap: nowrap;


                .contact-item {
                    width: 24%;
                    margin: 20px 0;
                    display: flex;
                    align-items: center;
                    color: var(--text-color);
                    font-family: var(--primary-font);
                    font-size: var(--font-18);
                    font-weight: var(--weight-400);
                    line-height: 1.5;
                    cursor: pointer;

                    img {
                        margin-inline-end: 16px;
                    }
                }

                .contact-item-small {
                    width: 2% !important;

                    img {
                        margin-inline-end: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            .header-background {
                height: 200px;
            }

            .company-details {
                flex-direction: column;
                align-items: center;
                // padding: 33px 0;

                .personal {
                    position: relative;
                    width: 100%;
                    top: 0;
                    left: 0;
                    transform: translateY(-30%);
                    margin-bottom: -13%;

                    .company-logo-container {
                        width: 32%;
                        height: 0;
                        padding: 0;
                        padding-bottom: 32%;
                        position: relative;
                    }

                    .company-logo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 7%;
                    }

                    .company-logo-placeholder {
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 40px;
                    }
                }

                .contact {
                    width: 100%;

                    .contact-item {
                        img {
                            margin-inline-end: 8px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 480px) {
            .header-background {
                height: 155px;
            }

            .company-details {

                .contact {
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .contact-item {
                        font-size: var(--font-16);
                        margin: 8px 0;
                        width: 49%;

                        img {
                            margin-inline-end: 6px;
                        }
                    }
                }
            }
        }
    }

    .company-content {
        display: flex;
        justify-content: space-between;
        margin: 0 6%;

        .left-content {
            width: 30%;
            margin-top: 160px;
            position: sticky;
            top: 25px;
            align-self: flex-start;

            .info-section {
                background-color: var(--white);
                padding: 30px;
                border-radius: 14px;
                margin-bottom: 25px;
                box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10);
            }

            .info-section {
                p {
                    color: var(--text-color);
                    font-family: var(--primary-font);
                    text-align: justify;
                    font-size: var(--font-16);
                    font-weight: var(--weight-600);
                }

                .description-section {
                    margin: 0 0 1.5em 0;

                    .description-text {
                        margin-bottom: 19px;
                        margin-top: 0px;
                    }

                    span {
                        text-align: justify;
                        font-size: var(--font-16);
                        font-weight: var(--weight-400);
                    }
                }

                .address-section,
                .year-of-establishment-section,
                .type-section,
                .orn-section,
                .rera-section {
                    margin: 1.5em 0;

                    span {
                        margin-inline-start: 14px;
                        text-align: justify;
                        font-size: var(--font-16);
                        font-weight: var(--weight-400);
                    }

                    .info-container {
                        position: relative;
                        display: inline-block;
                        margin-inline-start: 3px;
                    }

                    .info-container::after {
                        content: attr(title);
                        position: absolute;
                        bottom: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: var(--text-color);
                        color: var(--white);
                        padding: 5px 10px;
                        border-radius: 4px;
                        white-space: nowrap;
                        font-size: 0.8rem;
                        z-index: 100;
                        opacity: 0;
                        transition: opacity 0.3s ease-in-out;
                        pointer-events: none;
                    }

                    .info-container:hover::after {
                        opacity: 1;
                    }
                }

                .rera-section {
                    margin-bottom: 0;
                }
            }
        }

        .right-content {
            width: 66%;
            display: flex;
            flex-wrap: wrap;

            .seeMoreBtn {
                background-color: transparent;
                font-weight: var(--weight-500);
                font-size: var(--font-18);
                color: var(--primary-color);
                margin: 0 auto;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                padding: 80px 0px 77px 0px;
                text-decoration: underline;

            }

            .props {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                // margin-top: 54px;


            }

            .agents-grid {
                display: flex;
                justify-items: center;
                justify-content: space-around;
                flex-wrap: wrap;
                gap: 30px;

                .item {
                    width: calc(33% - 30px);
                    min-width: 258px;
                }
            }

            .loader,
            .loading,
            .error-message {
                text-align: center;
                font-size: 1.2rem;
                color: #666;
                margin: 20px 0;
                // height: 100%;
                width: 100%;
            }

            .error-message {
                color: red;
            }

            .no-properties {
                text-align: center;
                font-size: 1.1rem;
                color: #999;
                margin-top: 20px;
            }
        }

        @media (max-width:768px) {
            flex-direction: column;

            .left-content {
                position: unset;
                top: unset;
                align-self: unset;
            }

            .left-content,
            .right-content {
                width: 100%;
                margin-top: 20px;
            }

            .right-content {
                margin-top: 0px;

                .seeMoreBtn {
                    padding: 30px 0px 0px 0px;
                }

                .agents-grid {
                    justify-content: start;

                    .item {
                        width: calc(33% - 30px);
                        min-width: 290px;
                    }
                }

                .props {
                    flex-direction: column;
                    gap: 20px;

                    .apts {
                        width: 100%;
                    }
                }
            }
        }
    }
}