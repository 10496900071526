.popular-area {

    .popular-area-header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        h2 {
            color: var(--black);
            margin: 0;
            font-size: var(--font-35);
            font-weight: var(--weight-600);
            width: 100%;
        }

    }

    .popular-residential {
        width: 100%;
        padding: 1px 0;

        .slider {
            margin-bottom: 20px;

            .swiper {
                width: 100%;
                margin: unset;

            }

            .swiper-slide {
                max-width: 272px;
            }
        }
    }

    .prev-btn,
    .next-btn {
        -webkit-tap-highlight-color: transparent;
    }
}

:dir(rtl) .popular-area {
    padding: 70px 108px 70px 0px !important;
}

:dir(ltr) .popular-area {
    padding: 70px 0px 70px 108px;
}

:dir(rtl) .popular-area-header {
    padding: 0px 0px 0px 70px !important;
}

:dir(ltr) .popular-area-header {
    padding: 0px 70px 0px 0px;
}

@media (max-width: 580px) {
    .popular-area {
        .popular-area-header {
            padding: 0px 0px 0px 0px;
            margin-bottom: 25px !important;
        }

        h2 {
            padding-left: 20px;
            padding-right: 20px;
        }

        .popular-residential {
            .slider {
                .navigates {
                    display: none;
                }

                .swiper {
                    width: 100%;
                    margin: auto;

                }
            }
        }
    }

    :dir(rtl) .popular-area {
        padding: 0px 0px 0px 0px !important;
    }

    :dir(ltr) .popular-area {
        padding: 0px 0px 0px 0px !important;
    }
}